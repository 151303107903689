.ant-radio-button-wrapper {
    .radio-button-icon {
        border: 1px solid var(--border-blocks-primary-inactive);
        border-radius: var(--default-space);
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;

        svg {
            &:not(.custom-color) {
                fill: var(--icon-inactive);
            }
        }
    }
}

.ant-radio-button-wrapper-checked {
    &:not(.ant-radio-button-wrapper-disabled) {
        background-color: var(--surface-primary);
        border-color: var(--border-blocks-primary-active);
        outline: 1px solid var(--border-blocks-primary-active);

        &:first-child {
            border-color: var(--border-blocks-primary-active);

            &:hover {
                border-color: inherit;
            }
        }

        .title {
            font-weight: 700;
        }

        .radio-button-icon {
            border-color: var(--border-blocks-primary-active);
            outline: 1px solid var(--border-blocks-primary-active);
            background-color: var(--surface-secondary);

            svg {
                &:not(.custom-color) {
                    fill: var(--icon-active);
                }
            }
        }
    }
}

.ant-radio-button-wrapper {
    &.ant-radio-button-wrapper-disabled {
        border-color: var(--border-blocks-primary-disabled);
        background-color: var(--surface-disabled);

        * {
            color: var(--text-disabled);
        }

        .radio-button-icon {
            border-color: var(--border-blocks-primary-disabled);

            svg {
                fill: var(--icon-disabled);
            }
        }
    }
}

.ant-radio-group label.ant-radio-button-wrapper {
    border-radius: var(--default-space);
}