:root {
    // backgrounds and blocks
    --border-blocks-primary-inactive: var(--charcoal-250);
    --border-blocks-primary-active: var(--blue-1000);
    --border-blocks-primary-hovered: var(--blue-300);
    --border-blocks-primary-disabled: var(--charcoal-300);
    --border-blocks-divider-horizontal: var(--blue-400);

    // backgrounds and blocks -> dasboard cards
    --border-blocks-db-cards-inactive: var(--lemon-800);
    --border-blocks-db-cards-disabled: var(--charcoal-400);
    --border-blocks-db-cards-active: var(--orange-main);
    --border-blocks-db-cards-current-action: var(--blue-main);

    // backgrounds and blocks -> labels & statuses -> avatar
    --border-blocks-ls-avatar-inactive: var(--charcoal-main);

    // button main primary
    --border-button-main-primary-inactive: var(--blue-main);
    --border-button-main-primary-active: var(--blue-900);
    --border-button-main-primary-hover: var(--blue-800);
    --border-button-main-primary-disabled: var(--charcoal-300);

    // button main secondary
    --border-button-main-secondary-inactive: var(--blue-main);
    --border-button-main-secondary-active: var(--white-main);
    --border-button-main-secondary-hover: var(--charcoal-main);
    --border-button-main-secondary-disabled: var(--charcoal-350);

    // button main tertiary
    --border-button-main-tertiary-inactive: transparent;
    --border-button-main-tertiary-active: var(--blue-900);
    --border-button-main-tertiary-hover: var(--charcoal-100);
    --border-button-main-tertiary-disabled: transparent;

    // button success primary
    --border-button-success-primary-inactive: var(--white-main);
    --border-button-success-primary-active: var(--white-main);
    --border-button-success-primary-hover: var(--lemon-300);
    --border-button-success-primary-disabled: var(--charcoal-300);

    // button success tertiary
    --border-button-success-tertiary-inactive: transparent;
    --border-button-success-tertiary-active: var(--lemon-800);
    --border-button-success-tertiary-hover: var(--charcoal-100);
    --border-button-success-tertiary-disabled: var(--charcoal-300);

    // button error primary
    --border-button-error-primary-inactive: var(--coral-main);
    --border-button-error-primary-active: var(--coral-900);
    --border-button-error-primary-hover: var(--coral-800);
    --border-button-error-primary-disabled: var(--charcoal-200);

    // button error secondary
    --border-button-error-secondary-inactive: var(--coral-main);
    --border-button-error-secondary-active: var(--white-main);
    --border-button-error-secondary-hover: var(--charcoal-main);
    --border-button-error-secondary-disabled: var(--charcoal-300);

    // button error tertiary
    --border-button-error-tertiary-inactive: transparent;
    --border-button-error-tertiary-hover: var(--charcoal-100);
    --border-button-error-tertiary-disabled: transparent;

    // button warning tertiary
    --border-button-warning-tertiary-inactive: transparent;
    --border-button-warning-tertiary-active: var(--orange-main);
    --border-button-warning-tertiary-hover: var(--charcoal-100);
    --border-button-warning-tertiary-disabled: transparent;

    // input
    --border-input-inactive: var(--charcoal-300);
    --border-input-hover: var(--charcoal-main);
    --border-input-active: var(--blue-1000);
    --border-input-disabled: var(--charcoal-250);
    --border-input-error: var(--coral-main);

    //progress bar
    --border-progress-bar-inactive: var(--lemon-main);
    --border-progress-bar-inactive-disabled: var(--lemon-300);
    --border-progress-bar-active: var(--orange-main);
    --border-progress-bar-active-disabled: var(--orange-300);
    --border-progress-bar-disabled: var(--charcoal-200);

    //info blocks
    --border-info-block-info: var(--blue-500);
    --border-info-block-info-white: var(--blue-main);
    --border-info-block-warning: var(--orange-400);
    --border-info-block-error: var(--coral-400);
    --border-info-block-success: var(--lemon-800);

    //tabs
    --border-tabs-accent-divider: var(--charcoal-300);
}