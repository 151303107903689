@import 'style/shared/shared.scss';

.footer {
    display: flex;
    justify-content: space-between
}

.stepIndicator {
    @extend .typo-large;

    color: var(--text-button-main-tertiary-inactive);
}

.stepControls {
    display: flex;
    gap: var(--double-space);
}

.next {
    width: 103px;
}