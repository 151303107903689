@import 'style/shared/typography';
@import 'style/shared/shadow';

@mixin create-badge-styles($bg, $text-color) {
    @extend .typo-caption;

    background-color: $bg;
    color: $text-color;
    border-radius: 20px;
    padding: var(--half-space) var(--default-space);
    display: inline-block;
    min-width: 88px;
    max-height: 24px;
    text-align: center;
}

.badge {
    &-preinitial {
        @include create-badge-styles(var(--surface-status-draft), var(--text-status-draft));
    }

    &-initial {
        @include create-badge-styles(var(--surface-status-not-started), var(--text-status-not-started));
    }

    &-pending {
        @include create-badge-styles(var(--surface-status-inprogress), var(--text-status-inprogress));
    }

    &-success {
        @include create-badge-styles(var(--surface-status-completed), var(--text-status-completed));
    }

    &-error {
        @include create-badge-styles(var(--surface-status-rejected), var(--text-status-rejected));
    }

}