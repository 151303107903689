.obs {
    .ant-dropdown-menu-root {
        @extend .shadow-16;
        padding: var(--default-space);
        border-radius: var(--default-space);

        .ant-dropdown-menu-item {
            height: calc(var(--default-space) * 5);
            color: var(--text-dropdown-active);

            &.ant-dropdown-menu-item-active {
                &:not(.ant-dropdown-menu-item-disabled) {
                    color: var(--text-dropdown-hover);
                    background-color: var(--surface-dropdown-hover);
                }
            }

            &.ant-dropdown-menu-item-disabled {
                color: var(--text-dropdown-disabled);
                background-color: var(--surface-dropdown-disabled);
            }
        }
    }

}