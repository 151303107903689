.wrapper {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - var(--header-height));
    width: 100%;
    align-items: center;
    background-size: cover;
    background-position: center;

    --unshrinked-content-width: 464px;
}