@import '../../../style.scss';

.panel {
    max-width: var(--unshrinked-content-width);
    margin-top: calc(var(--default-space) * 6);
    padding: 0 var(--double-space);
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media (max-width:map-get($auth-width, unshrinkedContent)) {
    .panel {
        margin-top: var(--double-space);
    }

    .adaptiveContent {
        padding: var(--double-space);
    }
}

.noBorder {
    border: none;
}