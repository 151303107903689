@import 'style/shared/shared.scss';

.title {
    @extend .typo-headline-bold;
    color: var(--text-primary);
    padding-right: var(--triple-space);
}

.divider {
    border-bottom: 1px solid var(--border-blocks-primary-inactive);
    margin-left: calc(-1 * var(--double-space));
    margin-right: calc(-1 * var(--double-space));
    padding-left: var(--double-space);
}