.sandboxLogo {
    background-color: var(--surface-secondary);

    .icon {
        fill: var(--icon-warning);
        margin-bottom: 0;
    }
}


.reversedColors {
    background-color: var(--icon-warning);

    .icon {
        fill: var(--surface-secondary);
    }
}