@import 'style/shared/shared.scss';

.panel {
    background-color: var(--surface-secondary);
    border-radius: var(--double-space);
    padding: var(--triple-space);
    position: relative;

    &.top {
        margin-bottom: calc(var(--default-space) * 5);
    }

    &.topRight {
        margin-bottom: calc(var(--default-space) * 5);
    }

    &.right,
    &.rightTop,
    &.rightBottom {
        margin-left: calc(var(--default-space) * 7);
    }

    &.left,
    &.leftTop {
        margin-right: calc(var(--default-space) * 7);
    }

    &.bottomRight {
        margin-top: calc(var(--default-space) * 7);
    }
}

.header {
    display: flex;
    justify-content: space-between;
}

.title {
    @extend .typo-headline-bold;

    flex: 1 1 auto;
    color: var(--text-primary);
    margin-bottom: var(--triple-space);
}

.body {
    @extend .typo-body1;

    color: var(--text-secondary);
    margin-bottom: var(--triple-space);
}

button.dismiss {
    padding: 0;
    border: none;
}

.bubbles {
    position: absolute;
    top: 107px;
    fill: var(--surface-secondary);
    transition: inset 0.3s;

    &.right {
        left: -76px;
    }

    &.top {
        top: unset;
        bottom: -44px;
        left: calc(50% - 70px);
        transform: rotate(85deg);
        -ms-transform: rotate(85deg);
        -webkit-transform: rotate(-28deg);
    }

    &.rightTop {
        top: 14px;
        left: -76px;
        transform: scale(1, -1);
        -ms-transform: scale(1, -1);
        -webkit-transform: scale(1, -1);
    }

    &.rightBottom {
        top: unset;
        bottom: var(--default-space);
        left: -76px;
    }

    &.left {
        right: -76px;
        transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
    }

    &.leftTop {
        top: 14px;
        right: -76px;
        transform: scale(-1, -1);
        -ms-transform: scale(-1, -1);
        -webkit-transform: scale(-1, -1);
    }

    &.topRight {
        top: unset;
        bottom: -43px;
        left: calc(50% - 68px);
        transform: rotate(-32deg);
        -ms-transform: rotate(-32deg);
        -webkit-transform: rotate(-32deg);
    }

    &.bottomRight {
        bottom: unset;
        top: -51px;
        right: calc(50% - 50px);
        transform: rotate(85deg);
        -ms-transform: rotate(85deg);
        -webkit-transform: rotate(85deg);
    }
}