.wrapper {
    width: 100%;
    display: flex;

    button {
        width: 182px;
    }
}

.lookup {
    width: 648px;
    margin-right: var(--triple-space);
    display: inline-block;

    &>* {
        width: 100%;
    }
}

.submit {
    width: calc(var(--default-space) * 23);
}

.candidate {
    display: flex;
}

.name {
    width: 280px;
    margin-right: var(--default-space);
}

.bid {
    width: 102px;
    margin: 0 var(--default-space);
}

.locality {
    width: 202px;
    margin-left: var(--default-space);
}

.match {
    text-decoration: underline;
    font-weight: 500;
}