.configTabs {
    :global {
        .ant-tabs-nav {
            box-shadow: 0px 1.6px 3.6px 0px rgba(0, 87, 155, 0.11), 0px 0.3px 0.9px 0px rgba(0, 87, 155, 0.07);
            background-color: var(--surface-secondary);

            position: fixed;
            left: 0;
            top: var(--header-height);
            width: 100%;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            .ant-tabs-nav-wrap {
                max-width: calc(var(--supported-width) - calc(var(--default-space) * 12));
            }

        }

        .ant-tabs-content {
            margin-top: var(--header-height);
        }
    }
}