.electionTypes {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: auto;
}

.proceed {
    width: 100%;
    justify-content: center;
    margin-top: var(--triple-space);
}