@import 'style/shared/shared.scss';

.content {
    display: flex;
    padding: 0 var(--double-space);
    width: 100%;
    align-items: center;
    justify-content: space-between;
    height: calc(var(--default-space) * 10);
}

.label {
    @extend .typo-headline-bold;
}