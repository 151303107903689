.error {
    margin-bottom: var(--double-space);
}

.name {
    margin-left: var(--default-space);

    &>div {
        width: 520px;
    }
}

.id {
    width: calc(var(--default-space) * 9);
}

.candidate {
    display: flex;
}