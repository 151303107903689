@import 'style/shared/shared.scss';

.input {
    outline: none;
    overflow-y: auto;
    margin: 0 var(--double-space);

    p {
        margin-block-start: 0;
        margin-block-end: 0;

        &:first-of-type {
            margin-block-start: var(--default-space);
        }

        &:last-of-type {
            margin-block-end: var(--default-space);
        }
    }
}

.container {
    border: 1px solid var(--border-input-inactive);
    border-radius: var(--default-space);
}

.focused {
    border-color: var(--border-input-active);
}

.invalid {
    border-color: var(--border-input-error);
}

.placeholder {
    @extend .typo-medium;

    color: var(--text-input-placeholder);
    position: absolute;
    top: 68px;
    left: var(--double-space);
}

.validationError {
    @extend .typo-caption;

    margin-top: var(--default-space);
    color: var(--text-input-error-helper);
}