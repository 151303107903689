@import 'style/shared/shared.scss';
@import '../../style.scss';

.header {
    label {
        @extend .typo-headline-bold;

        color: var(--text-primary);
    }
}

.greeting {
    padding: var(--double-space) var(--triple-space);
}

.welcome {
    @extend .typo-title2-bold;
    color: var(--text-primary);
    padding-bottom: var(--half-space);
}

.bid {
    @extend .typo-body1;
    color: var(--text-secondary);
}

.body {
    display: flex;
    justify-content: center;
}

.actions {
    label {
        @extend .typo-body1;

        display: block;
        margin-top: var(--double-space);

        a {
            margin-left: calc(var(--default-space) * .5);
        }
    }
}

@media (max-width:map-get($auth-width, unshrinkedContent)) {

    .greeting {
        padding: var(--default-space) var(--double-space);
    }
}