.obs.ant-radio-wrapper {

    &.highlight-selected {
        .radio-title {
            color: var(--text-primary);
        }

        &.ant-radio-wrapper-checked {
            .radio-title {
                color: var(--text-active);
                font-weight: 700;
            }
        }
    }

    .ant-radio {
        height: 18px;
        width: 18px;

        .ant-radio-inner {
            height: 18px;
            width: 18px;
            border-color: var(--icon-inactive);
            border-width: 2px;
        }
    }

    &.ant-radio-wrapper-checked {
        .ant-radio {
            border-width: 4px;
            border-color: var(--icon-hover);

            .ant-radio-inner {
                border-color: var(--icon-active);
                background-color: var(--icon-active);

                &::after {
                    transform: scale(0.575);
                }
            }
        }
    }

    &:hover {
        &.ant-radio-wrapper-checked {
            .ant-radio {
                border-color: var(--icon-hover);

                .ant-radio-inner {
                    border-color: var(--icon-hover);
                    background-color: var(--icon-hover);
                }
            }
        }
    }
}