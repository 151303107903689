@mixin create-btn-styles($bg-color, $border-color, $text-color,
    $hover-bg-color, $hover-border-color, $hover-text-color,
    $disabled-bg-color, $disabled-border-color, $disabled-text-color,
    $icon-color, $hover-icon-color, $disabled-icon-color,
    $height: calc(var(--default-space) * 5),
    $typo: '.typo-large') {
    @extend #{$typo};

    background-color: $bg-color;
    border-color: $border-color;
    font-family: "Inter", sans-serif;
    color: $text-color;
    border-radius: var(--default-space);
    padding-left: var(--default-space);
    padding-right: var(--default-space);
    height: $height;
    line-height: $height;
    border-width: 1px;
    border-style: solid;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .divider {
        width: 1px;
        background-color: $text-color;
        height: $height;
        margin-left: var(--default-space);
    }

    >div {
        margin-left: var(--half-space);
        margin-right: var(--half-space);

        &:not(:only-child) {
            &:first-child {
                margin-right: var(--default-space);
                margin-left: calc(var(--half-space) * -1);
            }

            &:last-child {
                margin-left: var(--default-space);
                margin-right: calc(var(--half-space) * -1);
            }
        }
    }

    >svg,
    div>svg {
        fill: $icon-color;
        display: block;
    }

    >svg {
        &:first-child {
            // margin-right: calc(var(--default-space) * 1.5);
            margin-left: 0;
        }

        &:last-child {
            margin-left: calc(var(--default-space) * 1.5);
            margin-right: 0;
        }
    }

    &:hover {
        background-color: $hover-bg-color;
        border-color: $hover-border-color;
        color: $hover-text-color;

        svg {
            fill: $hover-icon-color;
        }
    }

    &:disabled,
    &.disabled {
        background-color: $disabled-bg-color;
        border-color: $disabled-border-color;
        color: $disabled-text-color;
        cursor: not-allowed;

        svg {
            fill: $disabled-icon-color;
        }
    }

    &.not-underline {
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

}

.btn {
    &-main {
        &-primary {
            @include create-btn-styles(var(--buttons-main-primary-inactive), var(--border-button-main-primary-inactive), var(--text-button-main-primary-inactive),
                var(--buttons-main-primary-hover), var(--border-button-main-primary-hover), var(--text-button-main-primary-hover),
                var(--buttons-main-primary-disabled), var(--border-button-main-primary-disabled), var(--text-button-main-primary-disabled),
                var(--icon-button-primary-inactive), var(--icon-button-primary-hover), var(--icon-disabled));

            &-md {
                @include create-btn-styles(var(--buttons-main-primary-inactive), var(--border-button-main-primary-inactive), var(--text-button-main-primary-inactive),
                    var(--buttons-main-primary-hover), var(--border-button-main-primary-hover), var(--text-button-main-primary-hover),
                    var(--buttons-main-primary-disabled), var(--border-button-main-primary-disabled), var(--text-button-main-primary-disabled),
                    var(--icon-button-primary-inactive), var(--icon-button-primary-hover), var(--icon-disabled), $height: calc(var(--default-space) * 4),
                    $typo: '.typo-medium');
            }
        }

        &-secondary {
            @include create-btn-styles(var(--buttons-main-secondary-inactive), var(--border-button-main-secondary-inactive), var(--text-button-main-secondary-inactive),
                var(--buttons-main-secondary-hover), var(--border-button-main-secondary-hover), var(--text-button-main-secondary-hover),
                var(--buttons-main-secondary-disabled), var(--border-button-main-secondary-disabled), var(--text-button-main-secondary-disabled),
                var(--icon-button-secondary-inactive), var(--icon-button-secondary-hover), var(--icon-disabled));

            &-md {
                @include create-btn-styles(var(--buttons-main-secondary-inactive), var(--border-button-main-secondary-inactive), var(--text-button-main-secondary-inactive),
                    var(--buttons-main-secondary-hover), var(--border-button-main-secondary-hover), var(--text-button-main-secondary-hover),
                    var(--buttons-main-secondary-disabled), var(--border-button-main-secondary-disabled), var(--text-button-main-secondary-disabled),
                    var(--icon-button-secondary-inactive), var(--icon-button-secondary-hover), var(--icon-disabled), $height: calc(var(--default-space) * 4),
                    $typo: '.typo-medium');
            }
        }

        &-tertiary {
            @include create-btn-styles(var(--buttons-main-tertiary-inactive), var(--border-button-main-tertiary-inactive), var(--text-button-main-tertiary-inactive),
                var(--buttons-main-tertiary-hover), var(--border-button-main-tertiary-hover), var(--text-button-main-tertiary-hover),
                var(--buttons-main-tertiary-disabled), var(--border-button-main-tertiary-disabled), var(--text-button-main-tertiary-disabled),
                var(--icon-button-tertiary-inactive), var(--icon-button-tertiary-hover), var(--icon-disabled));

            &-md {
                @include create-btn-styles(var(--buttons-main-tertiary-inactive), var(--border-button-main-tertiary-inactive), var(--text-button-main-tertiary-inactive),
                    var(--buttons-main-tertiary-hover), var(--border-button-main-tertiary-hover), var(--text-button-main-tertiary-hover),
                    var(--buttons-main-tertiary-disabled), var(--border-button-main-tertiary-disabled), var(--text-button-main-tertiary-disabled),
                    var(--icon-button-tertiary-inactive), var(--icon-button-tertiary-hover), var(--icon-disabled), $height: calc(var(--default-space) * 4),
                    $typo: '.typo-medium');
            }
        }
    }

    &-success {
        &-primary {
            @include create-btn-styles(var(--buttons-success-primary-inactive), var(--border-button-success-primary-inactive), var(--text-button-success-primary-inactive),
                var(--buttons-success-primary-hover), var(--border-button-success-primary-hover), var(--text-button-success-primary-hover),
                var(--buttons-success-primary-disabled), var(--border-button-success-primary-disabled), var(--text-button-success-primary-disabled),
                var(--icon-button-primary-inactive), var(--icon-button-primary-hover), var(--icon-disabled));

            &-md {
                @include create-btn-styles(var(--buttons-success-primary-inactive), var(--border-button-success-primary-inactive), var(--text-button-success-primary-inactive),
                    var(--buttons-success-primary-hover), var(--border-button-success-primary-hover), var(--text-button-success-primary-hover),
                    var(--buttons-success-primary-disabled), var(--border-button-success-primary-disabled), var(--text-button-success-primary-disabled),
                    var(--icon-button-primary-inactive), var(--icon-button-primary-hover), var(--icon-disabled), $height: calc(var(--default-space) * 4),
                    $typo: '.typo-medium');
            }
        }

        &-tertiary {
            @include create-btn-styles(var(--buttons-success-tertiary-inactive), var(--border-button-success-tertiary-inactive), var(--text-button-success-tertiary-inactive),
                var(--buttons-success-tertiary-hover), var(--border-button-success-tertiary-hover), var(--text-button-success-tertiary-hover),
                var(--buttons-success-tertiary-disabled), var(--border-button-success-tertiary-disabled), var(--text-button-success-tertiary-disabled),
                var(--icon-button-tertiary-inactive), var(--icon-button-tertiary-hover), var(--icon-disabled));

            &-md {
                @include create-btn-styles(var(--buttons-success-tertiary-inactive), var(--border-button-success-tertiary-inactive), var(--text-button-success-tertiary-inactive),
                    var(--buttons-success-tertiary-hover), var(--border-button-success-tertiary-hover), var(--text-button-success-tertiary-hover),
                    var(--buttons-success-tertiary-disabled), var(--border-button-success-tertiary-disabled), var(--text-button-success-tertiary-disabled),
                    var(--icon-button-tertiary-inactive), var(--icon-button-tertiary-hover), var(--icon-disabled), $height: calc(var(--default-space) * 4),
                    $typo: '.typo-medium');
            }
        }
    }

    &-error {
        &-primary {
            @include create-btn-styles(var(--buttons-error-primary-inactive), var(--border-button-error-primary-inactive), var(--text-button-error-primary-inactive),
                var(--buttons-error-primary-hover), var(--border-button-error-primary-hover), var(--text-button-error-primary-hover),
                var(--buttons-error-primary-disabled), var(--border-button-error-primary-disabled), var(--text-button-error-primary-disabled),
                var(--icon-button-secondary-inactive), var(--icon-button-secondary-hover), var(--icon-disabled));

            &-md {
                @include create-btn-styles(var(--buttons-error-primary-inactive), var(--border-button-error-primary-inactive), var(--text-button-error-primary-inactive),
                    var(--buttons-error-primary-hover), var(--border-button-error-primary-hover), var(--text-button-error-primary-hover),
                    var(--buttons-error-primary-disabled), var(--border-button-error-primary-disabled), var(--text-button-error-primary-disabled),
                    var(--icon-button-secondary-inactive), var(--icon-button-secondary-hover), var(--icon-disabled), $height: calc(var(--default-space) * 4),
                    $typo: '.typo-medium');
            }
        }

        &-secondary {
            @include create-btn-styles(var(--buttons-error-secondary-inactive), var(--border-button-error-secondary-inactive), var(--text-button-error-secondary-inactive),
                var(--buttons-error-secondary-hover), var(--border-button-error-secondary-hover), var(--text-button-error-secondary-hover),
                var(--buttons-error-secondary-disabled), var(--border-button-error-secondary-disabled), var(--text-button-error-secondary-disabled),
                var(--icon-button-secondary-inactive), var(--icon-button-secondary-hover), var(--icon-disabled));

            &-md {
                @include create-btn-styles(var(--buttons-error-secondary-inactive), var(--border-button-error-secondary-inactive), var(--text-button-error-secondary-inactive),
                    var(--buttons-error-secondary-hover), var(--border-button-error-secondary-hover), var(--text-button-error-secondary-hover),
                    var(--buttons-error-secondary-disabled), var(--border-button-error-secondary-disabled), var(--text-button-error-secondary-disabled),
                    var(--icon-button-secondary-inactive), var(--icon-button-secondary-hover), var(--icon-disabled), $height: calc(var(--default-space) * 4),
                    $typo: '.typo-medium');
            }
        }

        &-tertiary {
            @include create-btn-styles(var(--buttons-error-tertiary-inactive), var(--border-button-error-tertiary-inactive), var(--text-button-error-tertiary-inactive),
                var(--buttons-error-tertiary-hover), var(--border-button-error-tertiary-hover), var(--text-button-error-tertiary-hover),
                var(--buttons-error-secondary-disabled), var(--border-button-error-tertiary-disabled), var(--text-button-error-tertiary-disabled),
                var(--icon-button-secondary-inactive), var(--icon-button-secondary-hover), var(--icon-disabled));

            &-md {
                @include create-btn-styles(var(--buttons-error-tertiary-inactive), var(--border-button-error-tertiary-inactive), var(--text-button-error-tertiary-inactive),
                    var(--buttons-error-tertiary-hover), var(--border-button-error-tertiary-hover), var(--text-button-error-tertiary-hover),
                    var(--buttons-error-secondary-disabled), var(--border-button-error-tertiary-disabled), var(--text-button-error-tertiary-disabled),
                    var(--icon-button-secondary-inactive), var(--icon-button-secondary-hover), var(--icon-disabled), $height: calc(var(--default-space) * 4),
                    $typo: '.typo-medium');
            }
        }
    }

    &-warning {
        &-tertiary {
            @include create-btn-styles(var(--buttons-warning-tertiary-inactive), var(--border-button-warning-tertiary-inactive), var(--text-button-warning-tertiary-inactive),
                var(--buttons-warning-tertiary-hover), var(--border-button-warning-tertiary-hover), var(--text-button-warning-tertiary-hover),
                var(--buttons-warning-tertiary-disabled), var(--border-button-warning-tertiary-disabled), var(--text-button-warning-tertiary-disabled),
                var(--icon-button-tertiary-inactive), var(--icon-button-tertiary-hover), var(--icon-disabled));

            &-md {
                @include create-btn-styles(var(--buttons-warning-tertiary-inactive), var(--border-button-warning-tertiary-inactive), var(--text-button-warning-tertiary-inactive),
                    var(--buttons-warning-tertiary-hover), var(--border-button-warning-tertiary-hover), var(--text-button-warning-tertiary-hover),
                    var(--buttons-warning-tertiary-disabled), var(--border-button-warning-tertiary-disabled), var(--text-button-warning-tertiary-disabled),
                    var(--icon-button-tertiary-inactive), var(--icon-button-tertiary-hover), var(--icon-disabled), $height: calc(var(--default-space) * 4),
                    $typo: '.typo-medium');
            }
        }
    }
}