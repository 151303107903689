@import 'style/shared/shared.scss';
@import 'src/style/shared';

@supports not (container-type: size) {
    .actions {
        @media (max-width: 519px) {
            display: none;
        }
    }

    .burgerMenu {
        @media (min-width: 520px) {
            display: none;
        }
    }
}

.header {
    background-color: var(--header-surface);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    color: var(--header-text-address);
    border-bottom: 1px solid var(--divider-horizontal);
    width: 100%;
    container-type: size;
    position: sticky;
    z-index: var(--z-header);

    .logoutLink,
    .endTraining,
    a {
        color: var(--header-icon-text);

        &:hover {
            text-decoration: none;
        }
    }

    .logoBig {
        &:focus {
            outline: none;
        }

        margin: 0 var(--default-space) 0 var(--default-space);
        fill: var(--icon-info);
        cursor: pointer;

        @media (max-width: 520px) {
            margin-left: var(--default-space);
        }
    }

    svg {
        fill: var(--header-icon);
    }
}

//dividers
// .homeLinkWrapper,
.logoWrapper,
.locality,
.buttonWrapper,
.helpLinkWrapper,
.roleWrapper,
.endTrainingWrapper {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        width: 1px;
        height: 40px;
        background-color: var(--divider-vertical);
    }
}

.homeLinkWrapper,
.logoWrapper,
.locality {
    margin-left: 1px;

    &:before {
        left: 0;
        margin-left: -1px;
    }
}

.homeLinkWrapper {
    cursor: pointer;

    div {
        @extend .typo-caption;

        color: var(--header-icon-text);
    }
}

.buttonWrapper,
.helpLinkWrapper,
.logoutWrapper,
.roleWrapper,
.endTrainingWrapper {
    margin-right: 1px;

    &:before {
        right: 0;
        margin-right: -1px;
    }
}

//burger menu
.burgerNav {
    width: 70px;
}

.burger {
    width: 16px;
    height: 11px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
    overflow: hidden;
    cursor: pointer;

    span {
        width: 16px;
        height: 1px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: var(--header-icon);
        transition: all 0.5s;

        &:nth-of-type(2) {
            top: calc(50% - 5px);
        }

        &:nth-of-type(3) {
            top: calc(50% + 5px);
        }
    }
}

//button
.buttonWrapper {
    padding-right: var(--default-space);
    padding-left: var(--default-space);
}

//home, help link
.homeLinkWrapper,
.helpLinkWrapper,
.logoutWrapper {
    width: 70px;
    cursor: pointer;
}

.endTrainingWrapper {
    width: calc(var(--default-space) * 13);
    cursor: pointer;
}

.homeLink,
.helpLink,
.logoutLink,
.endTraining {
    display: block;
    text-align: center;
    text-decoration: none;

    svg {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5px;
    }
}

a.homeLink {
    color: var(--header-icon-text);
}

.fillAllSpace {
    margin-right: auto;
    display: flex;
    align-items: center;
}

//logo
.logoWrapper {
    width: 132px;
    text-align: center;
    cursor: pointer;
}



//locality
.locality {
    @extend .typo-body1;
    padding-right: var(--default-space);
    padding-left: var(--default-space);
}

.actions {
    display: flex;

    svg {
        fill: var(--header-icon-mixed);
    }

    @container (max-width: 519px) {
        display: none;
    }
}

.mobile {
    min-width: var(--mobile-supported-width);
    padding: calc(var(--default-space) * 1.5);
}

.navigation {
    height: calc(var(--default-space) * 5);
    width: calc(var(--default-space) * 5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--default-space);

    &:active,
    &:hover {
        background-color: var(--surface-dropdown-hover);
    }
}

.mobileActions {
    top: var(--header-height);
    z-index: var(--z-drawer);

    :global {
        .ant-drawer-mask {
            background-color: var(--surface-overlay);
        }

        .ant-drawer-content-wrapper {

            box-shadow: none;
        }

        .ant-drawer-content {
            background-color: var(-surface-dropdown-inactive);
            border-bottom-left-radius: var(--double-space);
            border-bottom-right-radius: var(--double-space);
        }
    }

    :global(.ant-drawer-body) {
        padding: calc(var(--default-space) * .5) 0 var(--double-space) 0;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            height: calc(var(--default-space) * 5);

            li {

                &:last-of-type {
                    &>div {
                        padding-left: var(--half-space);
                    }
                }

                svg {
                    fill: var(--icon-inactive);
                }

                height: calc(var(--default-space) * 5);
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 0 var(--triple-space);
                border-radius: var(--default-space);
                width: calc(100% - var(--double-space));
                margin-left: var(--default-space);

                &:hover,
                &:focus {
                    background-color: var(--surface-dropdown-hover);

                    span {
                        color: var(--text-dropdown-hover);
                    }
                }

                svg {
                    fill: var(--icon-inactive);
                }

                span {
                    @extend .typo-body1;

                    margin-left: var(--double-space);
                    color: var(--text-dropdown-inactive);
                }
            }
        }
    }
}

@container (min-width: 520px) {

    .burgerMenu,
    .logoMobileWrapper {
        display: none;
    }
}

@container (max-width: 519px) {

    .homeLinkWrapper,
    .logoWrapper {
        display: none;
        width: 0;
        height: 0;
        overflow: hidden;
    }

    .logoWrapper,
    .locality {
        &:before {
            content: none;
        }
    }
}