@import 'src/style/shared';

.wrapper {
    display: flex;
    flex-direction: column;
    height: 480px;
    width: 100%;
    align-items: center;
    margin-top: 64px;
    margin-bottom: -32px;

    .logoBig {
        &:focus {
            outline: none;
        }

        margin-left: -80px;
        fill: var(--icon-info);

        @media (max-width: 520px) {
            margin-left: var(--default-space);
        }

        svg {
            fill: var(--header-logo);
            width: 120%;
            height: 120%;
        }
    }


    h1 {
        @extend .typo-title2-bold;
        padding-bottom: var(--default-space);
        width: 540px;
    }

    .date {
        @extend .typo-large-bold;
        color: var(--text-secondary);
        padding-bottom: var(--double-space);
    }

    .info {
        color: var(--text-secondary);
        @extend .typo-large;
    }

    .button {
        margin-top: var(--triple-space);
    }

    .rightIcon {
        margin-left: 100px;
        margin-top: 64px;
    }

    .link {
        text-decoration: underline;
    }
}