:root {
    --white-main: #FFFFFF;
    --white-700: #FDFEFF;
    --white-600: #FBFDFF;
    --white-500: #FAFDFF;
    --white-400: #F9FDFF;
    --white-300: #F9FDFF;
    --white-200: #F8FCFF;
    --white-100: #F7FCFF;

    --charcoal-900: #080808;
    --charcoal-800: #10100F;
    --charcoal-main: #201F1E;
    --charcoal-700: #2B2A29;
    --charcoal-650: #373635;
    --charcoal-600: #4D4C4B;
    --charcoal-550: #636362;
    --charcoal-500: #797978;
    --charcoal-450: #908F8F;
    --charcoal-400: #9F9E9E;
    --charcoal-350: #BDBCBC;
    --charcoal-300: #C7C7C6;
    --charcoal-250: #D7D7D6;
    --charcoal-200: #E4E4E4;
    --charcoal-150: #EEEDED;
    --charcoal-100: #F8F8F8;

    --violet-900: #9A00B7;
    --violet-800: #AD00CF;
    --violet-main: #C000E5;
    --violet-700: #D040EC;
    --violet-600: #E080F2;
    --violet-500: #E699F5;
    --violet-400: #EDB3F8;
    --violet-300: #F2CCFA;
    --violet-200: #F7E0FB;
    --violet-100: #FCF2FD;

    --orange-900: #B75C00;
    --orange-800: #CF6800;
    --orange-main: #E57300;
    --orange-700: #EC9640;
    --orange-600: #F2B980;
    --orange-500: #F5C799;
    --orange-400: #F8D5B3;
    --orange-300: #FAE3CC;
    --orange-200: #FBEEE0;
    --orange-100: #FDF8F2;

    --lemon-900: #04AC00;
    --lemon-800: #04C300;
    --lemon-main: #05E500;
    --lemon-700: #64EF61;
    --lemon-600: #82F280;
    --lemon-500: #9BF599;
    --lemon-400: #B4F8B3;
    --lemon-300: #CDFACC;
    --lemon-200: #E1FBE0;
    --lemon-100: #F2FDF2;

    --coral-900: #AC0900;
    --coral-800: #C30A00;
    --coral-main: #E50C00;
    --coral-700: #EF6861;
    --coral-600: #F28680;
    --coral-500: #F59E99;
    --coral-400: #F8B7B3;
    --coral-300: #FACECC;
    --coral-200: #FBE1E0;
    --coral-100: #FDF3F2;

    --blue-1000: #0094FF;
    --blue-900: #003F6F;
    --blue-800: #004E8C;
    --blue-main: #00579B;
    --blue-700: #4081B4;
    --blue-600: #80ABCD;
    --blue-500: #99BCD7;
    --blue-400: #B3CDE1;
    --blue-300: #E0EAF3;
    --blue-200: #EBF2F7;
    --blue-100: #F2F6FA;
    --blue-50: #F7FCFF;
    --blue-25: #FDFEFF;
    --blue-15: #E5F4FF;
    --blue-000: rgba(0, 63, 111, 0.92);
}