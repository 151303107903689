@import 'space.scss';
@import 'shared.scss';
@import 'width.scss';
@import 'height.scss';
@import 'buttons.scss';
@import 'popup.scss';
@import 'form.scss';
@import 'collapse.scss';
@import 'tooltip.scss';
@import 'default-toast.scss';
@import 'link.scss';
@import 'z-index.scss';
@import 'rotate.scss';
@import 'collapse-like-card.scss';

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    font-family: 'Inter', sans-serif;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

#root {
    min-height: 100vh;
    min-width: 100%;
    width: min-content;
    background-color: var(--surface-primary);
}

p {
    margin: 0;
}

.noGlobalScroll {
    overflow: hidden;
}

article,
aside,
figure,
footer,
header,
hgroup,
nav,
section {
    display: block;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &::after {
        content: '';
        display: block;
    }
}

.obs-spin {
    fill: var(--icon-inactive);
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}