.ant-checkbox-wrapper .ant-checkbox-inner {
    border-color: var(--icon-inactive);
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--icon-active);
    border-color: var(--icon-active);
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled) .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
    border-color: var(--icon-active);
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
    border-color: var(--icon-hover);
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: var(--icon-hover);
    border-color: var(--icon-hover);
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner {
    border-color: var(--icon-hover);
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--icon-disabled);
    border-color: var(--icon-disabled);

    &:after {
        border-color: var(--icon-white);
    }
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled .ant-checkbox-inner {
    background-color: var(--icon-white);
    border-color: var(--icon-disabled);
}