@import '../../mixin/placeholder.scss';

.obs.ant-input-affix-wrapper,
input.ant-input {
    @extend .typo-body1;

    font-family: "Inter", sans-serif;
    height: calc(var(--default-space)* 5);
    padding: var(--default-space) var(--double-space);
    background-color: var(--surface-secondary);
    border: 1px solid var(--border-input-inactive);
    color: var(--text-input-inputed);
    border-radius: var(--default-space);

    &.small {
        height: calc(var(--default-space) * 4);
    }

    &:hover {
        border-color: var(--border-input-hover);
    }

    &:disabled {
        border-color: var(--border-input-disabled);
        background-color: var(--surface-disabled);
        color: var(--text-input-disabled);

        @include placeholder {
            color: var(--text-input-disabled);
        }
    }

    &:focus {
        border-color: var(--border-input-active);
    }

    &[aria-invalid="true"],
    &.ant-input-status-error {
        &:not(.ant-input-disabled) {
            &:not(.ant-input-borderless) {
                border-color: var(--border-input-error);
            }
        }

        color: var(--text-input-error-input);
        background-color: var(--surface-error);

        @include placeholder {
            color: var(--text-input-error-placeholder);
        }
    }

    @include placeholder {
        color: var(--text-input-placeholder);
    }
}

.small {
    input.ant-input {
        height: calc(var(--default-space) * 4);
    }
}

.obs.ant-input-affix-wrapper {
    &.small {
        height: calc(var(--default-space) * 4);
        padding: 0 var(--double-space);

        .ant-input-suffix {
            margin-right: calc(var(--default-space) * -1.3);

            .ant-input-clear-icon {
                &.ant-input-clear-icon-hidden {
                    width: 0;
                    padding: 0;
                }

                height: calc(var(--default-space) * 2.5);
                width: calc(var(--default-space) * 2.5);
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .ant-input-suffix {
        height: 100%;
        align-items: center;
        justify-content: center;

        .ant-input-clear-icon {
            height: var(--triple-space);
            width: var(--triple-space);
            margin: 0;
            padding: var(--half-space);

            svg {
                fill: var(--icon-inactive);
            }
        }
    }

    input {
        height: auto;
    }
}