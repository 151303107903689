@import 'style/shared/shared.scss';

.footer {
    @extend .typo-caption;

    display: flex;
    max-width: 480px;
    padding: calc(var(--default-space) * 6) var(--double-space);
    flex-direction: column;
    color: var(--text-secondary);

    @media(max-width: 414px) {
        padding-top: calc(var(--default-space) * 2);
        padding-bottom: calc(var(--default-space) * 2);
    }
}

.mobile {
    padding-top: var(--double-space);
}

.operatingHours {
    text-wrap: balance;
    text-align: center;
    margin-bottom: calc(var(--default-space) * 1.5);

    @media (max-width: 414px) {
        margin-bottom: 0px;
    }
}

.contact {
    @extend .typo-caption;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
        fill: var(--text-secondary);
        margin-right: calc(var(--default-space) * .5);
    }

    &:first-of-type {
        margin-right: var(--triple-space);
    }

    a {
        color: var(--text-link-primary);
    }
}

.contacts {
    display: flex;
    justify-content: center;
}

@media (max-width: 414px) {
    .footer {
        padding-bottom: calc(var(--default-space) * 4);
    }

    .contacts {
        flex-direction: column;
    }

    .contact {
        padding-top: var(--double-space);
    }
}