@import 'style/shared/shared.scss';

.editor {
    width: 888px;
}

.fixedHeight {
    min-height: 528px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: calc(var(--default-space) * 10);
    padding: 0 var(--double-space) 0 var(--triple-space);
}

.buttons {
    display: flex;

    &>button {
        margin-left: var(--double-space);
    }
}

.title {
    @extend .typo-headline-bold;

    color: var(--text-primary);
    position: relative;
}

.submit {
    width: calc(var(--default-space) * 23);
}

.content {
    padding: 0 var(--double-space);
    position: relative;
}

.footer {
    width: calc(100% + calc(var(--default-space)*4));
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: calc(var(--default-space) * 5);
    border-top: 1px solid var(--border-tabs-accent-divider);
    margin-left: calc(-1 * var(--double-space));
    margin-right: calc(-1 * var(--double-space));
    margin-top: var(--double-space);
}

.validVotesCounter {
    @extend .typo-body1;

    width: 194px;
    color: var(--text-secondary);
}

.fixedVotesHeight {
    min-height: 360px;
}

.ballotIdRef {
    position: absolute;
    background-color: transparent;
    width: 200px;
    height: 64px;
    left: -16px;
    top: -19px;
}

.actionsColumn {
    position: absolute;
    width: 180px;
    right: 16px;
    top: calc(var(--default-space) * 4);
    z-index: -1;
}