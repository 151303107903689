.customСolor {
    color: var(--text-disabled);
}

.votes {
    margin: 0 0 var(--default-space) 0;
    padding-left: var(--double-space);
}

.excluded,
.invalidVote {
    color: var(--text-disabled);
}