@import 'style/shared/shared.scss';
@import '../../style.scss';

.button {
    width: 100%;
    justify-content: center;
    margin-top: var(--double-space);

    &:not(:last-of-type) {
        margin-bottom: var(--double-space);
    }
}

.notSupportedOnMobile {
    border-top: 1px solid var(--surface-divider);
    padding: var(--double-space) 0;
    margin-top: var(--double-space);

    label {
        @extend .typo-body1;

        color: var(--text-primary);
    }
}

.options {
    width: 100%;
}

.tutorialLink {
    @extend .typo-body1;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: var(--double-space);
    text-align: center;
    color: var(--text-secondary);

    a {
        margin-left: calc(var(--default-space) * .5);
    }
}

@media (max-width:map-get($auth-width, unshrinkedContent)) {
    .tutorialLink {
        margin-top: var(--double-space);
    }

    .button {

        &:not(:last-of-type) {
            margin-bottom: var(--default-space);
        }
    }
}