:root {
    //backgrounds and blocks
    --surface-primary: var(--blue-50);
    --surface-secondary: var(--white-main);
    --surface-tertiary: var(--charcoal-100);
    --surface-headline: var(--white-main);
    --surface-disabled: var(--charcoal-100);
    --surface-error: var(--coral-100);
    --surface-success: var(--lemon-100);
    --surface-warning: var(--orange-200);
    --surface-info: var(--blue-100);
    --surface-filter-textfield: var(--charcoal-100);
    --surface-divider: var(--charcoal-250);
    --surface-active: var(--blue-200);
    --surface-overlay: var(--blue-000);
    --surface-info-tables: var(--blue-300);
    --surface-tie-resolved: var(--lemon-200);
    --surface-tie-red: var(--coral-200);
    --surface-tie-green: var(--lemon-200);

    //progress bar
    --surface-progress-bar-inactive: var(--lemon-200);
    --surface-progress-bar-inactive-hover: var(--lemon-400);
    --surface-progress-bar-inactive-disabled: var(--lemon-200);
    --surface-progress-bar-active: var(--orange-200);
    --surface-progress-bar-active-hover: var(--orange-400);
    --surface-progress-bar-active-disabled: var(--orange-200);
    --surface-progress-bar-disabled: var(--charcoal-100);

    //labels and statuses
    --surface-status-online: var(--lemon-200);
    --surface-status-confirmed: var(--lemon-200);
    --surface-status-completed: var(--lemon-200);
    --surface-status-not-eligible: var(--charcoal-300);
    --surface-status-eligible: var(--lemon-main);
    --surface-status-counter: var(--violet-200);
    --surface-status-counter-inactive: var(--violet-200);
    --surface-status-counter-active: var(--violet-600);
    --surface-status-draft: var(--charcoal-150);
    --surface-status-not-started: var(--charcoal-200);
    --surface-status-inprogress: var(--orange-200);
    --surface-status-inprogress-voted: var(--orange-100);
    --surface-status-pending: var(--orange-200);
    --surface-status-invalid: var(--coral-main);
    --surface-status-rejected: var(--coral-200);

    //dropdown
    --surface-dropdown-inactive: var(--white-main);
    --surface-dropdown-active: var(--blue-500);
    --surface-dropdown-hover: var(--blue-300);
    --surface-dropdown-focused: var(--blue-1000);
    --surface-dropdown-disabled: var(--charcoal-100);

    //tabs
    --surface-tabs-accent-active: var(--blue-200);
    --surface-tabs-filled-inactive: var(--white-main);

    --surface-tooltip: var(--blue-main);

    //collapse
    --surface-collapsed-active: var(--blue-900);
    --surface-collapsed-background: var(--blue-15);
    --surface-collapsed-hover: var(--blue-500);

    //scrollbar
    --surface-scrollbar-thumb: var(--charcoal-300);

    --surface-sandbox: var(--orange-main);
}