@import 'style/shared/shared.scss';
@import 'style/shared';

.wrapper {
    width: 100%;
    background-color: var(--surface-secondary);

    @extend .shadow-4;
}

.subheader {
    background-color: var(--surface-secondary);
    height: calc(var(--default-space)*7);

    margin-left: auto;
    margin-right: auto;
    padding: 0 calc(var(--default-space) * 8);
    position: relative;
    width: calc(100vw - var(--default-space)* 10);
    max-width: var(--supported-width);

    display: flex;
    align-items: center;
}

.sticky {
    position: sticky;
    top: var(--header-height);
    z-index: var(--z-sticky);
}

.pageHeader {
    height: var(--page-header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100vw - var(--default-space)* 10);
    max-width: var(--supported-width);
    padding: var(--default-space) calc(var(--default-space)* 8);
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.content {
    width: calc(100vw - var(--default-space)* 10);
    max-width: var(--supported-width);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.greeting {
    @extend .typo-title3-700;

    color: var(--text-primary);
    flex: 1 1 auto;

    .subgreeting {
        @extend .typo-body1;

        color: var(--text-secondary);
    }
}

.fixedWidth {
    width: var(--supported-width);
}

.notFixedWidth {
    height: var(--back-to-dashboard-line-height);
}

.fixedWidthSubheader {
    width: var(--supported-width);
    min-width: unset !important;
    max-width: unset;
}

@media (max-width: map-get($adaptive-mobile-width, adaptiveTabletContent)) {

    .notFixedWidth {
        height: auto;
        flex-direction: column;
        align-items: start;
        padding: var(--default-space) var(--double-space);
        min-width: unset !important;
        max-width: unset;
    }

    .greeting {
        @include typo-headline-mobile-700;
    }
}