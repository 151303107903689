.shadow {
    &-2 {
        box-shadow: 0px 0.800000011920929px 1.7999999523162842px 0px rgba(0, 0, 0, 0.13), 0px 0.15000000596046448px 0.44999998807907104px 0px rgba(0, 0, 0, 0.11);
    }

    &-4 {
        box-shadow: 0px 1.6px 3.6px 0px rgba(0, 87, 155, 0.11), 0px 0.3px 0.9px 0px rgba(0, 87, 155, 0.07);
    }

    &-8 {
        box-shadow: 0px 3.200000047683716px 7.199999809265137px 0px rgba(0, 0, 0, 0.13), 0px 0.6000000238418579px 1.7999999523162842px 0px rgba(0, 0, 0, 0.11);
    }

    &-16 {
        box-shadow: 0px 6.4px 14.4px 0px rgba(0, 87, 155, 0.13), 0px 1.2px 3.6px 0px rgba(0, 87, 155, 0.11);
    }

    &-32 {
        box-shadow: 0px 12.800000190734863px 28.799999237060547px 0px rgba(0, 0, 0, 0.22), 0px 2.4000000953674316px 7.400000095367432px 0px rgba(0, 0, 0, 0.18);
    }

    &-64 {
        box-shadow: 0px 25.600000381469727px 57.599998474121094px 0px rgba(0, 0, 0, 0.22), 0px 4.800000190734863px 14.399999618530273px 0px rgba(0, 0, 0, 0.18);
    }
}