@import 'style/shared/shared.scss';

.vote {
    min-height: calc(var(--default-space) * 5);
    align-items: flex-start;
    display: flex;
}

.column {
    @extend .typo-body1;

    min-height: calc(var(--default-space) * 5);
    padding-right: var(--default-space);
    flex: 0 0 auto;

    display: flex;
    align-items: center;

    color: var(--text-primary);

    padding-left: var(--double-space);

    &>* {
        flex: 1 1 auto;
    }
}

.excluded,
.empty {
    color: var(--text-disabled);
}

.name {
    width: 320px;
    padding-left: 0;
}

.bid {
    width: 113px;
}

.address {
    width: 215px;
    padding-right: 0;
}

.action {
    width: 180px;
    padding-left: 0;
}

.markAsInvalid,
.changeToValid {
    width: 142px;
    padding-left: 0;
    padding-right: 0;
    margin-left: calc(var(--default-space) * 3);
    margin-right: var(--default-space);
}

.remove {
    width: calc(var(--default-space) * 4);
    padding: 0;
}

.reason {
    width: 648px;
    padding: 0;
    flex-direction: column;
    justify-content: center;
}

.error {
    min-height: calc(var(--default-space)* 8);
}

.selectWrapper {
    height: calc(var(--default-space)* 5);
    display: flex;
    align-items: center;
}

.select {
    width: 648px;
    flex: 0 0 auto;
}

.reasonNotProvided {
    @extend .typo-caption;

    width: 648px;
    padding-bottom: var(--default-space);
    color: var(--text-input-error-helper);
}

button.dismiss {
    padding: 0;
    border: none;
}

.skeleton {
    width: 100%;
    display: flex;
    align-items: center;
    height: calc(var(--default-space) * 5);
}