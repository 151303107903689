.spinWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: var(--overlay-overlay-dark);
    z-index: var(--z-preloader);
}