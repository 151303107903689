.electionConfigCard {
    margin-bottom: calc(-1 * var(--triple-space));

    >div:first-child {
        position: sticky;
        top: 95px;
        z-index: 1;
        background-color: var(--surface-secondary);
        border-radius: var(--double-space) var(--double-space) 0 0;
        box-shadow: 0 1px var(--border-blocks-primary-inactive);
    }
}

.electionConfigTabs {
    &.tabs {
        :global {
            .ant-tabs-nav {
                box-shadow: none;
                position: sticky;
                left: 0;
                top: 176px;
                width: 100%;
                z-index: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--surface-secondary);

                .ant-tabs-nav-wrap {
                    max-width: unset;
                    border-bottom: 1px solid var(--surface-divider);
                }
            }

            .ant-tabs-content {
                margin-top: 0;
            }
        }
    }
}