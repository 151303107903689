.obs.ant-select,
.obs.ant-select.ant-select-single .ant-select-selector .ant-select-selection-search,
.obs.ant-select.ant-select-multiple .ant-select-selector .ant-select-selection-search,
.obs.ant-select.ant-select-single .ant-select-selector,
.obs.ant-select.ant-select-multiple .ant-select-selector {
    height: calc(var(--default-space)* 5);
    @extend .typo-body1;
}

.obs.ant-select:hover {
    .ant-select-arrow {
        &:not(:last-child) {
            opacity: unset;
        }
    }
}

.obs.ant-select.ant-select-single {
    &.ant-select-allow-clear {
        &:not(.lookup) {
            &:hover {
                &>span {
                    &:not(:only-of-type) {
                        &.ant-select-arrow {
                            opacity: 0;
                            top: 4px;
                        }

                        &.ant-select-clear {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.ant-select-dropdown .ant-select-item-empty {
    @extend .typo-body1;

    padding-left: var(--default-space);
    padding-top: 0;
    padding-bottom: 0;
    height: calc(var(--default-space)* 5);
    line-height: calc(var(--default-space)* 5);
    border-radius: var(--default-space);
    color: var(--text-dropdown-active);
}

.obs.ant-select.ant-select-single {
    &.ant-select-show-search {
        .ant-select-selector {
            .ant-select-selection-search-input {
                padding-left: 5px;
                padding-bottom: 3px;
            }
        }
    }
}

.obs.ant-select.ant-select-single,
.obs.ant-select.ant-select-multiple {
    &:hover:not(.ant-select-disabled) {
        .ant-select-selector {
            border-color: var(--border-input-hover);
        }
    }

    .ant-select-selector {
        .ant-select-selection-search-input {
            @extend .typo-body1;

            height: 100%;
            padding-right: var(--triple-space);
        }
    }

    .ant-select-arrow,
    .ant-select-clear {
        height: var(--triple-space);
        width: var(--triple-space);
        top: var(--default-space);
        margin: 0;
        padding: var(--half-space);
        background-color: transparent;
        transition: top 0.3s ease, opacity 0.3s ease;

        svg {
            fill: var(--icon-inactive);
        }
    }

    &.ant-select-disabled {
        .ant-select-selector {
            @extend .typo-body1;

            border-color: var(--border-input-disabled);
            background-color: var(--surface-disabled);

            .ant-select-selection-item {
                color: var(--text-input-disabled);
            }

            .ant-select-selection-placeholder {
                color: var(--text-input-disabled);
            }
        }

        .ant-select-clear,
        .ant-select-arrow {
            display: none;
        }
    }

    &.ant-select-status-error {
        .ant-select-selector {
            .ant-select-selection-item {
                color: var(--text-input-error-input);
            }

            border-color: var(--border-input-error);
            background-color: var(--surface-error);
            color: var(--text-input-error-input);

            .ant-select-selection-placeholder {
                color: var(--text-input-error-placeholder);
            }
        }

        .ant-select-arrow,
        .ant-select-clear {
            svg {
                fill: var(--icon-error);
                background-color: transparent;
            }
        }
    }

    .ant-select-selector {
        padding: 0 var(--double-space);
        background-color: var(--surface-secondary);
        border: 1px solid var(--border-input-inactive);
        color: var(--text-input-inputed);
        border-radius: var(--default-space);
    }
}

.ant-select-single .ant-select-selector .ant-select-selection-placeholder,
.ant-select-multiple .ant-select-selector .ant-select-selection-placeholder {
    @extend .typo-body1;

    line-height: calc(var(--default-space)* 5);
    color: var(--text-input-placeholder);
}

.ant-select-single {
    &:not(.ant-select-show-search) {
        .ant-select-selector .ant-select-selection-item {
            visibility: visible !important;
        }
    }
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-multiple .ant-select-selector .ant-select-selection-item {
    @extend .typo-body1;

    color: var(--text-input-inputed);
    line-height: calc(var(--default-space)* 5);
    height: calc(var(--default-space)* 5);
}


.ant-select.ant-select-single .ant-select-selector,
.ant-select.ant-select-multiple .ant-select-selector {
    padding: 0 var(--double-space);
    background-color: var(--surface-secondary);
    border: 1px solid var(--border-input-inactive);
    color: var(--text-input-inputed);
    border-radius: var(--default-space);
}

.ant-select-dropdown {
    padding: var(--half-space);

    .ant-select-item-option {
        @extend .typo-body1;

        height: calc(var(--default-space)* 5);
        line-height: calc(var(--default-space)* 5);
        padding: 0 calc(var(--default-space) * 1.5);
        border-radius: var(--default-space);
        flex-direction: row-reverse;
        color: var(--text-dropdown-active);
    }

    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: var(--surface-dropdown-hover);
        color: var(--text-dropdown-hover);
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: var(--surface-dropdown-active);
        color: var(--text-dropdown-active);

        .unchecked-icon {
            display: none;
        }
    }

    .ant-select-item-option-disabled {
        background-color: var(--surface-dropdown-disabled);
        color: var(--text-dropdown-disabled);

        .unchecked-icon {
            fill: var(--icon-dropdown-disabled);
        }

        &.ant-select-item-option-selected {
            .unchecked-icon {
                display: none;
            }
        }
    }
}

.obs {
    .ant-select-selection-overflow-item-rest {
        .ant-select-selection-item {
            padding: 0;
            margin: 0;
            background-color: transparent;

            .ant-select-selection-item-content {
                margin-right: var(--double-space);
            }
        }
    }
}

.small {

    &.obs.ant-select,
    &.obs.ant-select.ant-select-single .ant-select-selector .ant-select-selection-search,
    &.obs.ant-select.ant-select-multiple .ant-select-selector .ant-select-selection-search,
    &.obs.ant-select.ant-select-single .ant-select-selector,
    &.obs.ant-select.ant-select-multiple .ant-select-selector {
        height: calc(var(--default-space)* 4);
    }

    &.ant-select-single .ant-select-selector .ant-select-selection-placeholder,
    &.ant-select-multiple .ant-select-selector .ant-select-selection-placeholder {
        left: var(--double-space);
        line-height: calc(var(--default-space) * 4);
    }

    &.ant-select-single .ant-select-selector .ant-select-selection-item {
        line-height: calc(var(--default-space) * 4);
        height: calc(var(--default-space)* 4);
    }

    &.ant-select-multiple .ant-select-selector .ant-select-selection-item {
        line-height: calc(var(--default-space) * 4 - calc(var(--half-space) * .5));
        height: calc(var(--default-space)* 4);
    }

    &.ant-select-dropdown .ant-select-item-empty {
        height: calc(var(--default-space)* 4);
        line-height: calc(var(--default-space)* 4);
    }

    &.obs.ant-select.ant-select-single,
    &.obs.ant-select.ant-select-multiple {

        .ant-select-arrow,
        .ant-select-clear {
            height: calc(var(--default-space) * 2.5);
            width: calc(var(--default-space) * 2.5);
            top: 6px;
            right: 6px;
        }
    }
}