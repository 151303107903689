.ant-input-textarea-affix-wrapper.ant-input-affix-wrapper>textarea.ant-input::placeholder {
    @extend .typo-body1;
    color: var(--text-input-placeholder)
}

.ant-input-textarea-affix-wrapper.ant-input-affix-wrapper>textarea.ant-input {
    @extend .typo-body1;
    color: var(--text-input-inputed)
}

.ant-input-textarea-show-count .ant-input-data-count {
    @extend .typo-caption;
    color: var(--text-input-counter)
}

.ant-input-textarea-affix-wrapper.ant-input-affix-wrapper {
    padding-right: 1px;

    ::-webkit-scrollbar {
        width: 6px;
        border-radius: 3px;
    }

    ::-webkit-scrollbar-thumb {
        background: var(--surface-scrollbar-thumb);
        border-radius: 3px;
    }
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    border-color: var(--buttons-surfaces-error);
    background-color: var(--surface-error);
    color: var(--text-input-error-placeholder);
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input::placeholder {
    color: var(--text-input-error-placeholder);
}