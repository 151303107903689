@import 'style/shared/typography';

.title {
    position: absolute;
    top: calc(50vh + calc(var(--default-space)*8));
    color: var(--text-subheader);
    background-color: var(--overlay-overlay-dark);
    padding: var(--double-space);
    border-radius: var(--double-space);

    @extend .typo-subheadline1;
}