@import '../../mixin/placeholder.scss';

.obs.ant-picker {
    padding: 0 var(--default-space) 0 var(--double-space);
    background-color: var(--surface-secondary);
    border: 1px solid var(--border-input-inactive);
    border-radius: var(--default-space);
    height: calc(var(--default-space)* 5);

    &:hover {
        border-color: var(--border-input-hover);
    }

    &:focus {
        border-color: var(--border-input-active);
    }

    .ant-picker-input {
        &>input {
            @extend .typo-body1;
            color: var(--text-input-inputed);

            @include placeholder {
                color: var(--text-input-placeholder);
            }

        }
    }

    .ant-picker-suffix,
    .ant-picker-clear {
        height: var(--triple-space);
        width: var(--triple-space);
        margin: 0;
        padding: var(--half-space);
        cursor: pointer;

        svg {
            fill: var(--icon-inactive);
        }
    }

    &.ant-picker-status-error {
        &:not(.ant-picker-disabled) {
            border-color: var(--border-input-error); 
            background-color: var(--surface-error);
            color: var(--text-input-error-input);

            svg {
                fill: var(--icon-error);
            }

            input {
                @include placeholder {
                    color: var(--text-input-error-placeholder);
                }
            }
        }
    }

    &.ant-picker-disabled {
        border-color: var(--border-input-disabled);
        background-color: var(--surface-disabled);
        color: var(--text-input-disabled);

        .ant-picker-input {

            &>input {
                @include placeholder {
                    color: var(--text-input-disabled);
                }
            }
        }

        svg {
            fill: var(--icon-disabled);
        }
    }
}

.obs.ant-picker-dropdown {
    .ant-picker-cell {
        color: var(--text-picker-out-of-range);

        .ant-picker-cell-inner {
            @extend .typo-body1;

            line-height: var(--triple-space);
        }
    }

    .ant-picker-cell-today {
        .ant-picker-cell-inner {
            &::before {
                border-color: var(--border-input-active);
            }
        }
    }

    .ant-picker-cell-in-view {
        color: var(--text-primary);
    }

    .ant-picker-cell-disabled {
        color: var(--text-disabled);

        .ant-picker-cell-inner {
            &::before {
                border-color: var(--border-blocks-primary-inactive);
            }
        }
    }

    .ant-picker-today-btn {
        color: var(--text-link-primary);
    }

    .ant-picker-today-btn-disabled {
        color: var(--text-disabled);
    }

    .ant-picker-cell-selected {
        .ant-picker-cell-inner {
            background-color: var(--text-link-primary);
        }
    }
}