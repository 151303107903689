.headerWrapper {
    position: sticky;
    top: 0;
    z-index: var(--z-header);
    min-width: var(--supported-width);
}

.header {
    height: var(--header-height);
}

.content {
    width: 100%;
    padding-left: calc(var(--default-space) * 6);
    padding-right: calc(var(--default-space) * 6);
    width: var(--supported-width);
    margin-left: auto;
    margin-right: auto;
    padding-bottom: calc(var(--default-space) * 6);
    background-color: var(--surface-primary);
}