.ant-form-item {
    .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
        &::before {
            @extend .typo-caption;

            position: absolute;
            top: -2px;
            right: 3px;
            color: var(--text-error);
            font-size: 12px;
        }
    }
}

.label {
    //TODO: I'm not sure it is used. Check with Olga
    @extend .typo-caption;
    margin-bottom: var(--default-space);
    display: block;
    color: var(--text-input-label);
}

.ant-form {
    .ant-form-item-label {
        padding-bottom: var(--default-space);

        &>label {
            @extend .typo-caption;

            color: var(--text-input-label);
        }
    }
}

.ant-form-item-has-error {
    .ant-form-item-label {
        label {
            color: var(--text-input-error-label);
        }
    }
}