@import 'style/shared/shared.scss';

.header {
    border-top: none;
    background-color: var(--surface-tertiary);
    display: flex;
}

.column {
    @extend .typo-caption;
    padding: 0 var(--default-space) 0 var(--double-space);
    color: var(--text-secondary);
    margin: var(--default-space) 0;
    display: flex;
    align-items: center;
    height: calc(var(--default-space) * 2);

    &:not(:last-of-type) {
        border-right: 1px solid var(--surface-divider);
    }
}