.ant-tabs-top>.ant-tabs-nav {
    margin: 0;
}

.ant-tabs-top>.ant-tabs-nav::before {
    border-bottom: none;
}

.ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active {
    background-color: var(--surface-tabs-accent-active);
}

.ant-tabs .ant-tabs-tab {
    @extend .typo-subheadline1;
    padding: var(--default-space) var(--double-space);
    color: var(--text-tabs-accent-inactive);
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    @extend .typo-subheadline1-bold;
    color: var(--text-tabs-accent-active);
}

.ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar {
    height: var(--half-space);
}

.ant-tabs .ant-tabs-ink-bar {
    background: var(--text-tabs-accent-active);
}

.ant-tabs .ant-tabs-tab:hover {
    color: var(--text-tabs-accent-active);
}