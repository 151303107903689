.rte-bold {
    font-weight: bold;
}

.rte-italic {
    font-style: italic;
}

.rte-underline {
    text-decoration: underline;
}

.rte-strikethrough {
    text-decoration: line-through;
}

.rte-underlineStrikethrough {
    text-decoration-line: line-through underline;
}

.rte-link {
    color: var(--text-link-primary) !important;
    text-decoration: none;

    * {
        color: var(--text-link-primary) !important;
    }


    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}