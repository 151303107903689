@import 'style/shared/shared.scss';

.obsTable {

    overflow: clip;

    table {
        &>thead {
            &>tr:first-child {
                th:first-child {
                    border-start-start-radius: 0 !important;
                }

                th:last-child {
                    border-start-end-radius: 0 !important;
                }
            }
        }
    }

    border: 1px solid var(--border-blocks-primary-inactive);

    :global {
        .ant-table-thead {
            &>tr {
                &>th {

                    color: var(--text-secondary);
                    // padding: 0 var(--double-space);
                    background-color: var(--surface-tertiary);

                    // &:not(:last-child),
                    // &:not(.ant-table-selection-column),
                    // &:not(.ant-table-row-expand-icon-cell),
                    // &:not([colspan]),
                    // &::before {
                    //     height: 12px !important;
                    // }

                    .ant-table-column-sorter {
                        color: var(--icon-secondary);
                    }

                    svg,
                    .ant-table-filter-trigger svg {
                        fill: var(--icon-secondary);
                    }

                    .ant-table-column-sorter-up,
                    .ant-table-column-sorter-down,
                    .ant-table-filter-trigger {
                        &.active {
                            svg {
                                fill: var(--icon-secondary);
                            }
                        }
                    }
                }
            }
        }

        .ant-table-tbody {
            tr.ant-table-placeholder {
                background-color: var(--surface-secondary);
            }
        }

        .ant-table-tbody>tr>td.ant-table-cell {
            padding: 0 var(--default-space) 0 var(--double-space);
            border: none;

            &:not(.custom-cell-color) {
                color: var(--text-primary);
            }

            a {
                color: var(--text-link-primary);
                text-decoration: underline;
            }
        }

        .ant-table-wrapper {
            .ant-table-container {
                thead {
                    tr:first-child {
                        th:first-child {
                            border-start-start-radius: var(--double-space);
                        }
                    }
                }
            }
        }

        .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
            background-color: transparent;
        }

        .ant-spin {
            .ant-spin-dot {
                height: calc(var(--default-space)*6);
                width: calc(var(--default-space)*6);
            }
        }
    }


    // border radius
    border-radius: var(--double-space);

    table {
        border-radius: var(--double-space);

        tr {
            border-radius: var(--double-space);
        }
    }

    :global {

        .ant-table-bordered {
            border-radius: var(--double-space);

            .ant-table-container {
                border: 1px solid var(--border-blocks-primary-inactive);
            }
        }

        .ant-table {
            border-radius: var(--double-space);
        }
    }

    caption {
        border-top-left-radius: var(--double-space);
        border-top-right-radius: var(--double-space);
    }

    tbody {
        tr:last-of-type {
            border-bottom-left-radius: var(--double-space);
            border-bottom-right-radius: var(--double-space);
            border-bottom: none;

            td {
                border-bottom: none;
            }

            td:first-of-type {
                border-bottom-left-radius: var(--double-space);
            }

            td:last-of-type {
                border-bottom-right-radius: var(--double-space);
            }
        }
    }
}

.sticky {
    table {
        position: relative;

        &>thead {
            position: sticky;
            z-index: var(--z-sticky);
            top: var(--stickyOffset);
        }
    }
}

.headerDividerVisible {
    :global {
        .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
            background-color: var(--surface-divider);
            height: calc(100% - var(--double-space));
        }
    }
}

.smallHeader {
    th {
        @extend .typo-caption;
    }

    :global {
        .ant-table {
            .ant-table-thead {
                &>tr {
                    th {
                        height: calc(var(--default-space) * 4);
                        padding: 0 var(--double-space);
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

.small {

    tr {
        td {
            @extend .typo-body1;
        }
    }

    :global {
        .ant-table-tbody {
            &>tr {
                &>td {
                    &.ant-table-cell {
                        height: calc(var(--default-space) * 5);
                    }
                }
            }
        }
    }
}

.mediumHeader {
    th {
        @extend .typo-body1;
    }

    :global {
        .ant-table {
            .ant-table-thead {
                &>tr {
                    th {
                        height: calc(var(--default-space) * 6);
                        padding: 0 var(--default-space) 0 var(--double-space);
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

.medium {
    tr {
        td {
            @extend .typo-body1;
        }
    }

    :global {
        .ant-table-tbody {
            &>tr {
                &>td {
                    &.ant-table-cell {
                        height: calc(var(--default-space) * 7);
                    }
                }
            }
        }
    }
}

.largeHeader {
    th {
        @extend .typo-large;
    }

    :global {
        .ant-table {
            .ant-table-thead {
                &>tr {
                    th {
                        height: calc(var(--default-space) * 8);
                        padding: 0 var(--double-space);
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

.large {
    tr {
        td {
            @extend .typo-large;
        }
    }

    :global {
        .ant-table-tbody {
            &>tr {
                &>td {
                    &.ant-table-cell {
                        height: calc(var(--default-space) * 8);
                    }
                }
            }
        }
    }
}


.filterable {
    :global {
        .ant-table {
            .ant-table-thead {
                &>tr {
                    th {
                        padding: 0 !important;
                        height: auto !important;
                    }
                }
            }
        }
    }
}

.noOuterBorder {
    border: none;
    border-radius: 0;

    table {
        border-radius: 0;

        tr {
            border-radius: 0;
        }
    }

    tbody {
        tr:last-of-type {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            td {
                border-bottom: none;
            }

            td:first-of-type {
                border-bottom-left-radius: 0;
            }

            td:last-of-type {
                border-bottom-right-radius: 0;
            }
        }
    }
}