.listContainer {
    margin-bottom: calc(-6 * var(--default-space));
    border: 1px solid var(--border-blocks-primary-inactive);
    border-radius: var(--double-space);
    display: flex;
    flex-direction: column;

    &>* {
        flex: 0 0 auto;
    }
}

.listContainerWithoutBorders {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &>* {
        flex: 0 0 auto;
    }
}

.list {
    border-bottom-left-radius: var(--double-space);
    border-bottom-right-radius: var(--double-space);
    background-color: var(--surface-secondary);
    overflow: hidden !important;
    max-height: 800px;
}

.empty {
    background-color: var(--surface-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    border-bottom-left-radius: var(--double-space);
    border-bottom-right-radius: var(--double-space);
    flex: 1 0 auto;
}