@import 'date-picker.scss';
@import 'dropdown.scss';
@import 'radio.scss';
@import 'radio-button.scss';
@import 'rich-text-editor.scss';
@import 'error.scss';
@import 'input.scss';
@import 'label.scss';
@import 'select.scss';
@import 'text-area.scss';
@import 'checkbox.scss';
@import 'tabs.scss';

.ant-form-item {
    padding-bottom: var(--double-space);
    margin-bottom: 0;

    &.no-padding {
        padding-bottom: 0;
    }
}