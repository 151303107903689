@import 'style/shared/shared.scss';

.listItem {
    display: flex;
    background-color: var(--surface-secondary);
    color: var(--text-primary)
}

.column {
    @extend .typo-body1;

    padding: var(--default-space) var(--default-space) var(--default-space) var(--double-space);
    display: flex;
    align-items: center;
    height: calc(var(--default-space) * 5);
    flex: 0 0 auto;
}

.statusIndicator {
    width: var(--double-space);
    height: var(--double-space);
    border-radius: 50%;
    margin: auto;
}

.valid {
    background-color: var(--surface-status-eligible);
}

.invalid {
    background-color: var(--surface-status-invalid);
}

.deleted {
    background-color: var(--surface-status-not-eligible);
}

.linkColor {
    color: var(--text-link-primary);
    text-decoration: underline;
    cursor: pointer;
}

.tooltip {
    max-width: unset;
}