@import 'src/style/shared';

.roleNav {
    @extend .typo-caption;

    padding-left: var(--double-space);
    padding-right: var(--double-space);

    .roleIcon {
        text-align: center;
    }

    .roleSwitcher {
        color: var(--header-icon-text);

        &:not(.isDisabled) {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .roleBrowser {
        white-space: nowrap;
    }
}

.asListItem {
    padding-left: 0;
    padding-right: 0;
    fill: var(--header-icon-mixed);

    .roleSwitcher {
        text-decoration: none;
        color: var(--header-icon-text);
    }

    .roleIcon {
        display: flex;
        align-items: center;

        svg {
            height: 20px;
            width: 20px;
        }
    }

    >div {
        display: flex;
        align-items: center;
    }
}

.greeting {
    padding-bottom: var(--double-space);
}

.bid {
    @extend .typo-body1;
    color: var(--text-secondary);
}

.name {
    @extend .typo-title2-bold;
    color: var(--text-primary);
    padding-bottom: var(--half-space);
}

label {
    @extend .typo-headline-bold;
    color: var(--text-primary);
}

.divider {
    border-bottom: 1px solid var(--border-blocks-primary-inactive);
    margin: 0 calc(-1 * var(--double-space)) var(--double-space) calc(-1 * var(--double-space));
}