@import 'style/shared/typography';

.empty {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.option {
    :global {
        .ant-select-item-option-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}

.alreadySelected {
    @extend .typo-caption;

    margin-left: var(--double-space);
    background-color: var(--buttons-main-primary-active);
    border-radius: var(--half-space);
    padding: var(--half-space) var(--default-space);
    color: var(--text-subheader);
}

.clearIcon {
    opacity: 0;
}