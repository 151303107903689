@import 'style/shared/buttons.scss';
@import 'style/shared';

.footer {
    display: flex;
    gap: var(--default-space);
    padding-top: var(--double-space);
    padding-bottom: calc(var(--default-space) * .5);

    @media (max-width: map-get($adaptive-mobile-width, adaptiveContent)) {
        flex-direction: column;
    }

    &.reverseButtons {
        flex-direction: row-reverse;

        button[class~='btn-main-primary'] {
            @extend .btn-main-secondary;
        }

        button[class~='btn-main-secondary'] {
            @extend .btn-main-primary;
        }
    }

    &>* {
        flex: 1;
        justify-content: center;
    }
}