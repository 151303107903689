@import 'style/shared';

.colorOption {
    border: 1px solid var(--border-button-main-primary-inactive);
    width: calc(var(--default-space) * 9);
    height: var(--triple-space);
    margin: var(--default-space) 0;
    flex: 0 0 auto
}

.select {
    width: 84px !important;
    flex: 0 0 auto;

    :global {
        .ant-select-selection-item>div {
            width: var(--triple-space) !important;
        }
    }
}