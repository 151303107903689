@import 'shadow.scss';

.default-toast {
    &.ant-notification-notice {
        @extend .shadow-16;

        .ant-notification-notice-message {
            @extend .typo-body1;

            margin-bottom: 0;
            color: var(--text-primary);
        }
    }
}