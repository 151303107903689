@import 'style/shared/shared.scss';

.footer {
    padding-top: var(--default-space);
}

.note {
    @extend .typo-body1;

    padding-bottom: var(--double-space);
    color: var(--text-secondary);
}