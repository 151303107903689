@import 'style/shared/shared.scss';

.inputEmails {
    border: none !important;
    box-shadow: none !important;
}

.tagClass {
    @extend .typo-body1;

    padding: var(--default-space);
    color: var(--text-active);
    border-radius: var(--triple-space);
    height: calc(var(--default-space) * 5);
    background-color: var(--surface-active);
    border: 2px solid var(--text-active);
    margin-bottom: var(--default-space);

    span {
        vertical-align: middle;
        margin-bottom: var(--half-space);
    }

    svg {
        fill: var(--text-active);
        height: var(--double-space);
        width: var(--double-space);
        vertical-align: middle;
    }
}

.invalidTagClass {
    color: var(--text-error);
    background-color: var(--surface-tie-red);
    border: 2px solid var(--text-error);

    svg {
        fill: var(--text-error);
    }
}

.validation {
    @extend .typo-caption;

    margin-top: var(--half-space);
    color: var(--text-input-error-helper);
}

.errorInput {
    border-color: var(--text-error);
}