.headerWrapper {
    position: sticky;
    top: 0;
    z-index: var(--z-header);
}

.headerWrapperSupportWidth {
    min-width: var(--supported-width);
}

.content {
    background-color: var(--surface-primary);
}