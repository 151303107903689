@import 'style/shared/shared.scss';

.containerWrapper {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border-color: var(--border-blocks-primary-inactive);
    border-width: 1px;

    &:not(:last-of-type) {
        margin-bottom: var(--default-space);
    }

    &::before {
        content: none !important;
    }

    &>span {
        &:first-of-type {
            height: auto;
        }

        width: 100%;
    }
}

.container {
    display: flex;
    align-items: flex-start;
    gap: var(--double-space);
    margin: var(--double-space);
}

.icon {
    height: calc(var(--default-space) * 6);
    width: calc(var(--default-space) * 6);
}

.selectLabel {
    display: flex;
    flex-direction: column;

    .labelTitle {
        @extend .typo-large;

        label[class~='ant-radio-button-wrapper-checked'] & {
            @extend .typo-large-bold;
        }
    }

    .labelSubTitle {
        @extend .typo-caption;
        color: var(--text-secondary);
    }

    .labelText {
        @extend .typo-caption;
        color: var(--text-primary);
        padding-top: var(--default-space);
        text-wrap: initial;
    }

    color: var(--text-primary);
    text-wrap: balance;
}