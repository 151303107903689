.rotate-90 {
    transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
}

.rotate-270 {
    transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
}

.flip-horizontal {
    transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
}

.flip-vertical {
    transform: scale(1, -1);
    -ms-transform: scale(1, -1);
    -webkit-transform: scale(1, -1);
}