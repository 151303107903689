@import 'style/shared/shared.scss';

.error {
    margin-bottom: var(--double-space);
}

.name {
    margin-left: var(--default-space);

    &>div {
        width: 518px;
    }
}

.id {
    width: calc(var(--default-space) * 9);
}

.election {
    display: flex;
}

.details {
    @extend .typo-body1-bold;

    display: flex;
    flex-direction: column;
    gap: var(--default-space);
}

.title {
    @extend .typo-body1-bold;
}

.value {
    @extend .typo-body1;
}