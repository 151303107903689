.card {
    background-color: var(--surface-secondary);
    border-radius: var(--double-space);
    border: 1px solid var(--border-blocks-primary-inactive);
}

.highlighted {
    border: 1px solid var(--border-blocks-primary-active);
}

.highlightedWithOrange {
    border: 1px solid var(--border-button-warning-tertiary-active);
}

.headerLine {
    height: 1px;
    background-color: var(--border-blocks-primary-inactive);
    width: 100%;
}

.highlightedBackground {
    border-bottom: 1px solid var(--surface-dropdown-focused);
    height: 0;
}

.cardContent {
    padding: var(--double-space);
}