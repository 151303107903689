@import 'style/shared/shared.scss';

.collapse-like-card {

    .ant-collapse.ant-collapse-icon-position-start {
        background-color: var(--surface-headline);
        border: 1px solid var(--border-blocks-primary-inactive);
        border-radius: var(--double-space);

        .ant-collapse-header {
            @extend .typo-subheadline1-bold;

            color: var(--text-primary);
            padding: var(--default-space) var(--double-space);
            flex-direction: row-reverse;
            align-items: center;
        }

        .ant-collapse-item {
            border: none;

            &.ant-collapse-item-active {
                .ant-collapse-expand-icon svg {
                    transform: rotate(-90deg);
                }
            }
        }

        .ant-collapse-content {
            background-color: transparent;
            border-top: 1px solid var(--surface-divider);
        }

        .ant-collapse-content-box {
            padding: 0;
        }

        .ant-collapse-expand-icon {
            height: calc(var(--triple-space) + var(--half-space));
            flex-grow: 1;
            padding-inline-start: var(--double-space);

            svg {
                fill: var(--icon-hover);
                width: var(--default-space);
                height: var(--double-space);
                transform: rotate(90deg);
                margin-top: 2px;
            }
        }

        .ant-collapse-header-text {
            flex-grow: 0;
        }
    }
}