@import 'style/shared/typography';
@import 'style/shared/shadow';

.tooltip {
    & [role='tooltip'] {
        @extend .shadow-16;
        @extend .typo-caption;

        background-color: var(--surface-tooltip);
        color: var(--text-subheader);
    }

    --antd-arrow-background-color:var(--surface-tooltip);
    z-index: var(--z-tooltip);
}