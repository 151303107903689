@import 'style/shared/shared.scss';

.table {
    width: 100%;
    border-radius: 0;
    border: none;

    :global {
        .ant-table {
            .ant-table-thead {
                &>tr {
                    th {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}

.statusIndicator {
    width: var(--double-space);
    height: var(--double-space);
    border-radius: 50%;
    margin: auto;
}

.valid {
    background-color: var(--surface-status-eligible);
}

.invalid {
    background-color: var(--surface-status-invalid);
}

.deleted {
    background-color: var(--surface-status-not-eligible);
}

.customСolor {
    color: var(--text-disabled);
}

.linkColor {
    color: var(--text-link-primary);
    text-decoration: underline;
    cursor: pointer;
}

.votes {
    margin: 0 0 var(--default-space) 0;
    padding-left: var(--double-space);
}

.invalidVote {
    color: var(--text-disabled);
}

.tooltip {
    max-width: calc(var(--triple-space)*30);
}

.validVotesCounter {
    @extend .typo-caption;

    color: var(--text-secondary);
}

.singlePoputTitle {
    padding-bottom: var(--default-space);
}

.firstBallotId {
    position: absolute;
    height: calc(var(--default-space) * 5);
    width: 116px;
    top: 0;
    left: 0;
}