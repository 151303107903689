.contentBlock {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: auto;
}

.checkmarkIcon {
    fill: var(--icon-success);
}

.dismissIcon {
    fill: var(--icon-error);
}