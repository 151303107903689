@import 'style/shared/shared.scss';

.alignBottom {
    margin-top: var(--double-space);
    align-items: flex-start;
}

.inputAndLabel {
    display: flex;
    align-items: flex-start;
    gap: var(--double-space);
}

.wideInput {
    width: 817px;
}

.label {
    @extend .typo-small;

    color: var(--text-secondary);
    padding-top: calc(var(--default-space) * 4);
}