//TODO: if you have another styling of Collapse, make this class name more specific and define general rules for the new class name
.obs-collapse {
    border: none;

    &.ant-collapse {
        .ant-collapse-item {
            border-radius: 0;
            border: none;

            .ant-collapse-header {
                @extend .typo-body1-bold;

                color: var(--text-primary);
                padding: 0;
                height: calc(var(--default-space) *4);
                display: flex;
                align-items: center;
                background-color: var(--surface-dropdown-inactive);
                border-radius: 0;
                border: none;

                &:hover {
                    background-color: var(--surface-collapsed-hover);
                }
            }

            .ant-collapse-content {
                border: none;
                .ant-collapse-content-box{
                    padding: 0;
                }
            }

            .ant-collapse-expand-icon {
                width: 20px;
                padding: 0;
                margin: 0 8px;

                svg {
                    fill: var(--icon-dropdown-inactive);
                }
            }
        }
    }
}