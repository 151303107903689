@import 'style/shared/shared.scss';

.statusIndicator {
    width: var(--double-space);
    height: var(--double-space);
    border-radius: 50%;
    margin: auto;
}

.valid {
    background-color: var(--surface-status-eligible);
}

.invalid {
    background-color: var(--surface-status-invalid);
}

.customСolor {
    color: var(--text-disabled);
}

.commentTitle {
    @extend .typo-body1;

    color: var(--text-primary);
    padding-bottom: var(--default-space);
}

.commentBody {
    @extend .typo-body1;

    color: var(--text-primary);
}