@import 'style/shared/typography';
@import '../../../style.scss';

.header {
    background-color: var(--surface-primary);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: calc(var(--default-space) * 1.5) var(--triple-space);
    width: 100%;
    border-bottom: 1px solid var(--divider-horizontal);
    height: var(--header-height);
    position: sticky;
    top: 0;
    z-index: var(--z-header);

    svg {
        fill: var(--header-logo);
    }

}

.opened {
    width: 100vw;
}

.logoWrapper:focus {
    outline: none;
}

.helpLink,
.logoutLink {
    @extend .typo-caption;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: var(--header-icon-text);
    cursor: pointer;
    padding: var(--half-space) var(--default-space);
    margin: calc(-1 * var(--default-space));
    border-radius: var(--default-space);

    &:hover {
        background-color: var(--surface-dropdown-hover);
    }

    svg {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: var(--half-space);
    }
}

.helpLinkWrapper {
    margin-right: var(--double-space);
}

.logoutWrapper {
    margin-left: var(--double-space);

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 83px;
        transform: translateY(-50%);
        display: block;
        width: 1px;
        height: 40px;
        background-color: var(--divider-vertical);
    }
}

.actions {
    display: flex;

    svg {
        fill: var(--header-icon-mixed);
    }

    @container (max-width: 519px) {
        display: none;
    }
}

.navigation {
    display: none;
}

@media (max-width:map-get($auth-width, unshrinkedContent)) {
    .header {
        padding-left: var(--double-space);
        padding-right: var(--double-space);
    }

    .actions {
        display: none;
    }

    .navigation {
        height: calc(var(--default-space) * 5);
        width: calc(var(--default-space) * 5);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: var(--default-space);
        cursor: pointer;

        &:active,
        &:hover {
            background-color: var(--surface-dropdown-hover);
        }
    }
}

.mobileActions {
    top: var(--header-height);
    z-index: var(--z-drawer);

    :global {

        .ant-drawer-mask {
            background-color: var(--surface-overlay);
        }

        .ant-drawer-content-wrapper {

            box-shadow: none;
        }

        .ant-drawer-content {
            background-color: var(--surface-dropdown-inactive);
            border-bottom-left-radius: var(--double-space);
            border-bottom-right-radius: var(--double-space);
        }
    }

    :global(.ant-drawer-body) {
        padding: calc(var(--default-space) * .5) 0 var(--double-space) 0;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            height: calc(var(--default-space) * 5);

            li {

                &:last-of-type {
                    &>div {
                        padding-left: var(--half-space);
                    }
                }

                svg {
                    fill: var(--icon-inactive);
                }

                height: calc(var(--default-space) * 5);
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 0 var(--triple-space);
                border-radius: var(--default-space);
                width: calc(100% - var(--double-space));
                margin-left: var(--default-space);

                &:hover,
                &:focus {
                    background-color: var(--surface-dropdown-hover);

                    span {
                        color: var(--text-dropdown-hover);
                    }
                }

                svg {
                    fill: var(--icon-inactive);
                }

                span {
                    @extend .typo-body1;

                    margin-left: var(--double-space);
                    color: var(--text-dropdown-inactive);
                }
            }
        }
    }
}