@import 'style/shared/shared.scss';
@import '../../style.scss';

.header {
    &:focus {
        outline: none;
    }

    display: flex;
    align-items: center;

    svg {
        margin-bottom: calc(var(--default-space) * 5);
        fill: var(--icon-info);

        line {
            stroke: var(--icon-info);
        }
    }

    label {
        @extend .typo-title2-bold;

        color: var(--text-primary);
    }
}

.button {
    width: 100%;
    justify-content: center;

    &:not(:last-of-type) {
        margin-bottom: var(--default-space);
    }
}

.mobile {
    label {
        @extend .typo-title3;
    }
}

.actions {

    button {
        width: 100%;
        justify-content: center;

        &:not(:last-of-type) {
            margin-bottom: var(--default-space);
        }
    }
}

.short {
    display: none;
}

.desktopOnlyInfo {
    @extend .typo-body1-bold;

    color: var(--text-primary);
    padding-bottom: var(--default-space);
}

@media (max-width:map-get($auth-width, unshrinkedContent)) {
    .short {
        display: inline;
    }

    .long {
        display: none;
    }

    .button {
        margin-top: var(--default-space);

        &:not(:last-of-type) {
            margin-bottom: var(--default-space);
        }
    }
}