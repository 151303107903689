@import 'style/shared/shared.scss';

.table {
    padding-top: var(--triple-space);
}

.error {
    margin-top: var(--triple-space);
}

.footer {
    padding-top: var(--default-space);
}

.popupBodyText {
    padding-bottom: var(--default-space);
    color: var(--text-secondary);

    .stationText {
        @extend .typo-body1-bold;
    }

}

.popupFooterText {
    @extend .typo-body1-bold;
}