@import 'style/shared/shared.scss';

.input {
    width: 271px;
    flex: 0 0 auto;
}

.label {
    @extend .typo-small;

    color: var(--text-secondary);
    padding-top: var(--double-space);
}

.inputAndLabel {
    display: flex;
    align-items: center;
    gap: var(--double-space);
}