@import 'style/shared/shared.scss';

.content {
    display: flex;
    padding: 0 var(--double-space);
    width: 100%;
    align-items: center;
    justify-content: space-between;
    height: calc(var(--default-space) * 10);
}

.label {
    @extend .typo-subheadline1-bold;
}

.emailInput {
    min-height: 144px;
}

.inputBody {
    display: flex
}

.subHeader {
    @extend .typo-small;

    color: var(--text-secondary);
    padding-left: var(--double-space);
    margin: var(--default-space) 0;
}

.inputLabel {
    @extend .typo-caption;

    color: var(--text-input-label);
    margin: var(--default-space) 0;
}

.select {
    width: 271px;
    margin-bottom: var(--double-space);
}

.checkboxTitle {
    @extend .typo-body1-bold;

    color: var(--text-active);
}

.checkboxSubtitle {
    @extend .typo-small;

    color: var(--text-secondary);
}

.checkbox {
    :global {
        .ant-checkbox {
            align-self: self-start;
            margin-top: var(--half-space);
        }
    }

    margin-bottom: var(--triple-space);
}

.emailInput {
    padding-bottom: var(--triple-space);
}

.a {
    margin-bottom: var(--triple-space);
}