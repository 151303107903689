body[data-color-theme='VoterTheme'] {
    --header-surface: var(--white-100);
    --header-text-address: var(--charcoal-main);
    --header-icon: var(--blue-main);
    --header-link-text: var(--blue-main);
    --header-icon-text: var(--blue-main);
    --header-text-disabled: var(--charcoal-500);
    --header-icon-text-disabled: var(--charcoal-500);
    --divider-vertical: var(--blue-400);
    --divider-horizontal: var(--blue-400);
    --header-icon-mixed: var(--charcoal-main);
}