.error-message {
    @extend .typo-caption;
    margin-top: var(--default-space);
    color: var(--text-error);
}

select {
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 40px;
    background: url(../../icons/down-colored.svg) no-repeat center right 23px;
}