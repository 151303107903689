@import 'style/shared/shared.scss';

.empty {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(var(--default-space) * 25);
}

.iconWrapper {
    height: calc(var(--default-space) * 7);
    width: calc(var(--default-space) * 7);
    background-color: var(--surface-primary);
    border-radius: 50%;
}

.icon {
    fill: var(--icon-info);
}

.description {
    @extend .typo-headline;

    color: var(--text-primary);
}