@import 'style/shared/shared.scss';

.note {
    width: 100%;
    border: 1px solid var(--border-blocks-db-cards-active);
    background-color: var(--surface-error);
    padding: var(--default-space) var(--default-space);
    border-radius: var(--default-space);
    display: flex;
    align-items: center;
}

.noteContent {
    width: 100%;
}

.icon {
    flex-shrink: 0;
    height: calc(var(--default-space) * 3);
    width: calc(var(--default-space) * 3);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: var(--default-space);
}

.title {
    @extend .typo-caption-700;
}

.warningTitle {
    color: var(--text-warning);
}

.activeTitle,
.infoTitle {
    color: var(--text-active);
}

.errorTitle {
    color: var(--text-error);
}

.successTitle {
    color: var(--text-success);
}

.text {
    @extend .typo-caption;
    width: 100%;
}

.regular-warningText,
.regular-infoText,
.regular-errorText,
.regular-activeText,
.regular-successText {
    color: var(--text-secondary);
}

.filled-warningText {
    color: var(--text-warning);
}

.filled-infoText,
.filled-activeText {
    color: var(--text-active);
}

.filled-errorText {
    color: var(--text-error);
}

.filled-successText {
    color: var(--text-success);
}

.whiteBackground {
    background-color: var(--surface-secondary);
}

.infoBackground {
    background-color: var(--surface-info-tables);
}

.activeBackground {
    background-color: var(--surface-info-tables);
}

.warningBackground {
    background-color: var(--surface-warning);
}

.errorBackground {
    background-color: var(--surface-error);
}

.warningBorder {
    border: 1px solid var(--border-info-block-warning);
}

.warningRegularBorder {
    border: 1px solid var(--border-blocks-db-cards-active);
}

.infoBorder,
.activeBackground {
    border: 1px solid var(--border-info-block-info);
}

.infoRegularBorder {
    border: 1px solid var(--border-info-block-info-white);
}

.errorBorder {
    border: 1px solid var(--border-info-block-error);
}

.successBorder {
    border: 1px solid var(--border-info-block-success);
}

.infoIcon,
.activeIcon {
    svg {
        fill: var(--icon-info);
    }
}

.warningIcon {
    svg {
        fill: var(--icon-warning);
    }
}

.errorIcon {
    svg {
        fill: var(--icon-error);
    }
}

.successIcon {
    svg {
        fill: var(--icon-success);
    }
}

.regularIconTitle {
    color: var(--text-primary);
}

.regularIconText {
    color: var(--text-secondary);
}