@mixin create-font-styles($size, $height, $weight: 400, $style: normal) {
    font-size: $size;
    font-style: $style;
    font-weight: $weight;
    line-height: $height;
}

.typo {
    &-title1 {
        @include create-font-styles(32px, 40px);
    }

    &-title1-bold {
        @include create-font-styles(32px, 40px, 600);
    }

    &-title2 {
        @include create-font-styles(28px, 36px);
    }

    &-title2-bold {
        @include create-font-styles(28px, 36px, 600);
    }

    &-title3 {
        @include create-font-styles(24px, 32px);

        &-700 {
            @include create-font-styles(24px, 32px, 700);
        }
    }

    &-headline {
        @include create-font-styles(20px, 28px);

        &-bold {
            @include create-font-styles(20px, 28px, 700);
        }
    }

    &-subheadline1 {
        @include create-font-styles(18px, 24px);

        &-bold {
            @include create-font-styles(18px, 24px, 600);
        }

        &-700 {
            @include create-font-styles(18px, 24px, 700);
        }
    }

    &-body1 {
        @include create-font-styles(14px, 20px, 500);

        &-bold {
            @include create-font-styles(14px, 20px, 700);
        }
    }

    &-large {
        @include create-font-styles(16px, 20px, 500);

        &-bold {
            @include create-font-styles(16px, 20px, 700);
        }
    }

    &-medium {
        @include create-font-styles(14px, 18px, 500);
    }

    &-caption {
        @include create-font-styles(12px, 16px, 500);

        &-700 {
            @include create-font-styles(12px, 16px, 700);
        }
    }

    &-small {
        @include create-font-styles(10px, 14px);

        &-semibold {
            @include create-font-styles(10px, 14px, 600);
        }
    }
}

//mobile mixins

@mixin typo-title3-mobile-h1() {
    @include create-font-styles(24px, 26px, 700);
}

@mixin typo-body1-mobile-500() {
    @include create-font-styles(14px, 20px, 500);
}

@mixin typo-headline-mobile-700() {
    @include create-font-styles(20px, 28px, 700);
}

@mixin typo-caption-mobile() {
    @include create-font-styles(12px, 16px, 500);
}
