@import 'style/shared/shared.scss';

.checkboxTitle {
    @extend .typo-body1-bold;

    color: var(--text-active);
}

.checkboxSubtitle {
    @extend .typo-small;

    color: var(--text-secondary);
}

.checkbox {
    :global {
        .ant-checkbox {
            align-self: self-start;
            margin-top: var(--half-space);
        }
    }
}

.wideInput {
    width: 817px;
}

.input {
    width: 271px;
    flex: 0 0 auto;
}

.inputAndLabel {
    display: flex;
    align-items: flex-start;
    gap: var(--double-space);
}

.alignBottom {
    margin-top: var(--double-space);
    align-items: flex-start;
}