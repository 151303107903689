:root {
    --icon-error: var(--coral-main);
    --icon-success: var(--lemon-800);
    --icon-info: var(--blue-main);
    --icon-warning: var(--orange-main);
    --icon-active: var(--blue-main);
    --icon-inactive: var(--charcoal-700);
    --icon-secondary: var(--charcoal-500);
    --icon-disabled: var(--charcoal-450);
    --icon-white: var(--white-main);
    --icon-hover: var(--charcoal-900);

    --icon-progress-bar-inactive: var(--lemon-800);
    --icon-progress-bar-inactive-disabled: var(--lemon-600);
    --icon-progress-bar-active: var(--orange-main);
    --icon-progress-bar-active-disabled: var(--orange-600);
    --icon-progress-bar-disabled: var(--charcoal-400);
    --icon-progress-bar-between-done: var(--charcoal-main);
    --icon-progress-bar-between-disabled: var(--charcoal-400);

    --icon-button-primary-inactive: var(--white-main);
    --icon-button-primary-active: var(--white-main);
    --icon-button-primary-hover: var(--blue-300);
    --icon-button-secondary-inactive: var(--charcoal-main);
    --icon-button-secondary-active: var(--white-main);
    --icon-button-secondary-hover: var(--charcoal-main);
    --icon-button-secondary-disabled: var(--charcoal-400);
    --icon-button-tertiary-active: var(--white-main);
    --icon-button-tertiary-inactive: var(--charcoal-main);
    --icon-button-tertiary-hover: var(--charcoal-900);
    --icon-button-tertiary-disabled: var(--charcoal-400);

    --icon-button-icon-active: var(--white-main);
    --icon-button-icon-inactive: var(--charcoal-main);
    --icon-button-icon-hover: var(--charcoal-900);
    --icon-button-icon-disabled: var(--charcoal-400);

    //dropdown
    --icon-dropdown-inactive: var(--charcoal-main);
    --icon-dropdown-active: var(--blue-900);
    --icon-dropdown-hover: var(--blue-700);
    --icon-dropdown-disabled: var(--charcoal-400);
}