.proceed,
.cancel {
    width: 100%;
    justify-content: center;
}

.buttonsWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: var(--double-space);
    gap: var(--default-space);

    @media(max-width: 580px) {
        flex-direction: column;
    }
}