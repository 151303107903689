.toolbar {
    display: flex;
    min-height: calc(var(--default-space) * 5);
    background: var(--surface-primary);
    padding: var(--default-space) var(--double-space);
    border-top-left-radius: var(--default-space);
    border-top-right-radius: var(--default-space);
    border-bottom: 1px solid var(--border-blocks-primary-inactive);
    align-items: center;
}

.divider {
    padding-right: var(--double-space);
}

.extraSmallDivider {
    padding-right: var(--half-space);
}

button.button {
    padding: 0;
}

button.active {
    background-color: var(--buttons-main-tertiary-active);

    div {
        svg {
            fill: var(--text-button-main-primary-active);
        }
    }
}