@import 'style/shared/typography';

.contentWrapper {
    margin-top: var(--double-space);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - (var(--page-header-height) + var(--back-to-dashboard-line-height) + var(--double-space)));
    align-items: center;

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        align-items: center;
        margin-top: var(--triple-space);

        .title {
            @extend .typo-title1-bold;

            @media(max-width: 414px) {
                font-size: 24px;
                font-weight: 700;
                line-height: 32px;
            }
        }

        .title {
            color: var(--text-primary);
            margin-bottom: calc(var(--default-space) * 4);
        }

        .text {
            @extend .typo-subheadline1-bold;

            color: var(--text-primary);
            padding: 0 var(--double-space);
            text-align: center;
            margin-top: var(--triple-space);
        }
    }
}

.backBtn {
    min-width: 210px;
    margin-top: calc(var(--default-space) * 4);
}