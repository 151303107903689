@import 'style/shared';

.editor,
.viewLink {
    height: calc(var(--default-space) * 9);
    display: flex;
    align-items: center;
    padding: 0 var(--double-space);
    gap: var(--double-space);
    width: 600px;
}

.linkEditor {
    @extend .shadow-8;

    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    z-index: var(--z-tooltip);
    width: 600px;
    opacity: 0;
    background-color: var(--surface-secondary);
    border-radius: var(--default-space);
    transition: opacity 0.5s;
    will-change: transform;
}

.link {
    width: 100%;
}