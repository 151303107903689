@import 'style/shared/shared.scss';

.wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    opacity: 0.3;
    top: 0;
    left: 0;
}

:global(.ant-modal .ant-modal-close) {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 19px;
    inset-inline-end: var(--double-space);
    height: var(--triple-space);
    width: var(--triple-space);
}

.dismissIcon {
    display: flex;

    svg {
        height: var(--double-space);
        width: var(--double-space);

        fill: var(--icon-inactive);
    }
}

:global {
    .ant-modal-root {
        .ant-modal-mask {
            background-color: var(--surface-overlay);
            opacity: 0.75 !important;
            z-index: var(--z-popup);
        }
    }

    .ant-modal {

        font-family: 'Inter',
            sans-serif;

        .ant-modal-header {
            margin-bottom: var(--double-space);
        }

        .ant-modal-content {
            padding: var(--double-space);
            border-radius: var(--double-space);
        }

        .ant-modal-footer {
            margin-top: 0;
        }
    }
}