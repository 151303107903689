@import 'style/shared/shared.scss';

.option {
    margin: 0;

    .title {
        @extend .typo-body1;
    }

    :global {
        span.ant-radio {
            align-self: flex-start;
            margin-top: var(--half-space);
        }
    }
}

.radioNote {
    @extend .typo-small;

    color: var(--text-secondary);
    margin-top: var(--half-space);
}

.input {
    width: 271px;
    flex: 0 0 auto;
}

.label {
    @extend .typo-small;

    color: var(--text-secondary);
    padding-top: var(--double-space);
}

.inputAndLabel {
    display: flex;
    align-items: center;
    gap: var(--double-space);
}