@import 'style/shared/typography';

.contentBlock {
    @extend .typo-body1;
    color: var(--text-secondary);
}

.boldText {
    @extend .typo-body1-bold;
    color: var(--text-primary);
    padding-top: var(--default-space);
}

.submit,
.cancel {
    width: 100%;
    justify-content: center;
}

.buttonsWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: var(--double-space);
    gap: var(--default-space);

    @media(max-width: 580px) {
        flex-direction: column;
    }
}