@import 'style/shared/shared.scss';

.checkboxTitle {
    @extend .typo-body1-bold;

    color: var(--text-active);
}

.checkboxSubtitle {
    @extend .typo-small;

    color: var(--text-secondary);
}

.checkbox {
    :global {
        .ant-checkbox {
            align-self: self-start;
            margin-top: var(--half-space);
        }
    }
}