:root {

    // input
    --text-input-label: var(--charcoal-main);
    --text-input-counter: var(--charcoal-350);
    --text-input-label-hover: var(--blue-1000);
    --text-input-type-carette: var(--blue-1000);
    --text-input-placeholder: var(--charcoal-550);
    --text-input-inputed: var(--charcoal-main);
    --text-input-helper: var(--charcoal-450);
    --text-input-disabled: var(--charcoal-400);
    --text-input-error-label: var(--coral-main);
    --text-input-error-input: var(--coral-main);
    --text-input-error-placeholder: var(--coral-700);
    --text-input-error-helper: var(--coral-700);

    //dropdown
    --text-dropdown-inactive: var(--blue-main);
    --text-dropdown-active: var(--blue-900);
    --text-dropdown-hover: var(--blue-700);
    --text-dropdown-disabled: var(--charcoal-400);

    // basic styles
    --text-active: var(--blue-main);
    --text-primary: var(--charcoal-main);
    --text-secondary: var(--charcoal-550);
    --text-tertiary: var(--charcoal-450);
    --text-disabled: var(--charcoal-400);
    --text-subheader: var(--white-main);
    --text-body: var(--white-main);
    --text-link-primary: var(--blue-1000);
    --text-link-secondary: var(--coral-main);
    --text-link-tertiary: var(--lemon-main);
    --text-avatars: var(--white-main);
    --text-caption: var(--lemon-800);
    --text-error: var(--coral-main);
    --text-success: var(--lemon-800);
    --text-warning: var(--orange-main);
    --text-info: var(--blue-main);
    --text-hover: var(--charcoal-900);

    //button main primary
    --text-button-main-primary-inactive: var(--white-main);
    --text-button-main-primary-active: var(--white-main);
    --text-button-main-primary-hover: var(--blue-300);
    --text-button-main-primary-disabled: var(--charcoal-450);

    //button main secondary
    --text-button-main-secondary-inactive: var(--blue-main);
    --text-button-main-secondary-active: var(--white-main);
    --text-button-main-secondary-hover: var(--charcoal-main);
    --text-button-main-secondary-disabled: var(--charcoal-450);

    //button main tertiary
    --text-button-main-tertiary-inactive: var(--blue-800);
    --text-button-main-tertiary-active: var(--white-main);
    --text-button-main-tertiary-hover: var(--charcoal-main);
    --text-button-main-tertiary-disabled: var(--charcoal-450);

    //button success primary
    --text-button-success-primary-inactive: var(--white-main);
    --text-button-success-primary-active: var(--white-main);
    --text-button-success-primary-hover: var(--lemon-300);
    --text-button-success-primary-disabled: var(--charcoal-450);

    //button success tertiary
    --text-button-success-tertiary-inactive: var(--lemon-900);
    --text-button-success-tertiary-active: var(--white-main);
    --text-button-success-tertiary-hover: var(--charcoal-main);
    --text-button-success-tertiary-disabled: var(--charcoal-400);

    //button error primary
    --text-button-error-primary-inactive: var(--white-main);
    --text-button-error-primary-active: var(--white-main);
    --text-button-error-primary-hover: var(--coral-300);
    --text-button-error-primary-disabled: var(--charcoal-400);

    //button error secondary
    --text-button-error-secondary-inactive: var(--coral-main);
    --text-button-error-secondary-active: var(--white-main);
    --text-button-error-secondary-hover: var(--charcoal-main);
    --text-button-error-secondary-disabled: var(--charcoal-400);

    //button error tertiary
    --text-button-error-tertiary-inactive: var(--coral-main);
    --text-button-error-tertiary-hover: var(--charcoal-main);
    --text-button-error-tertiary-disabled: var(--charcoal-400);

    //button warning tertiary
    --text-button-warning-tertiary-inactive: var(--orange-800);
    --text-button-warning-tertiary-active: var(--white-main);
    --text-button-warning-tertiary-hover: var(--charcoal-main);
    --text-button-warning-tertiary-disabled: var(--charcoal-400);

    // labels and statuses
    --text-status-online: var(--lemon-main);
    --text-status-confirmed: var(--lemon-main);
    --text-status-completed: var(--lemon-800);
    --text-status-draft: var(--charcoal-450);
    --text-status-not-started: var(--charcoal-550);
    --text-status-inprogress: var(--orange-main);
    --text-status-pending: var(--orange-main);
    --text-status-rejected: var(--coral-700);

    // progress bar
    --text-progress-bar-inactive: var(--charcoal-main);
    --text-progress-bar-inactive-disabled: var(--charcoal-550);
    --text-progress-bar-active: var(--charcoal-main);
    --text-progress-bar-active-disabled: var(--charcoal-550);
    --text-progress-bar-disabled: var(--charcoal-400);

    //tabs
    --text-tabs-accent-inactive: var(--charcoal-main);
    --text-tabs-accent-active: var(--blue-main);

    --text-picker-out-of-range: var(--blue-600);

}