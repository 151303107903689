:root {

    //main primary
    --buttons-main-primary-inactive: var(--blue-main);
    --buttons-main-primary-active: var(--blue-900);
    --buttons-main-primary-hover: var(--blue-800);
    --buttons-main-primary-disabled: var(--charcoal-200);
    --buttons-main-icon-hover: var(--charcoal-200);

    //main secondary
    --buttons-main-secondary-inactive: transparent;
    --buttons-main-secondary-active: var(--blue-900);
    --buttons-main-secondary-hover: var(--charcoal-200);
    --buttons-main-secondary-disabled: var(--white-main);

    //main tertiary
    --buttons-main-tertiary-inactive: transparent;
    --buttons-main-tertiary-active: var(--blue-900);
    --buttons-main-tertiary-hover: var(--charcoal-200);
    --buttons-main-tertiary-disabled: transparent;

    //success primary
    --buttons-success-primary-inactive: var(--lemon-800);
    --buttons-success-primary-active: var(--lemon-900);
    --buttons-success-primary-hover: var(--lemon-800);
    --buttons-success-primary-disabled: var(--charcoal-200);

    //success tertiary
    --buttons-success-tertiary-inactive: transparent;
    --buttons-success-tertiary-active: var(--lemon-800);
    --buttons-success-tertiary-hover: var(--charcoal-200);
    --buttons-success-tertiary-disabled: var(--charcoal-200);

    //error primary
    --buttons-error-primary-inactive: var(--coral-main);
    --buttons-error-primary-active: var(--coral-900);
    --buttons-error-primary-hover: var(--coral-800);
    --buttons-error-primary-disabled: var(--charcoal-200);

    //error secondary
    --buttons-error-secondary-inactive: transparent;
    --buttons-error-secondary-active: var(--coral-900);
    --buttons-error-secondary-hover: var(--charcoal-100);
    --buttons-error-secondary-disabled: var(--white-main);

    //error tertiary
    --buttons-error-tertiary-inactive: transparent;
    --buttons-error-tertiary-hover: var(--charcoal-200);

    //warning primary
    --buttons-warning-primary-inactive: var(--orange-main);

    //warning tertiary
    --buttons-warning-tertiary-inactive: transparent;
    --buttons-warning-tertiary-active: var(--orange-900);
    --buttons-warning-tertiary-hover: var(--charcoal-100);
    --buttons-warning-tertiary-disabled: transparent;

    // surfaces
    --buttons-surfaces-divider: var(--blue-400);
    --buttons-surfaces-error: var(--coral-600);
    --buttons-surfaces-info: var(--blue-600);
}