@import 'style/shared/shared.scss';

.note {
    @extend .typo-body1;

    color: var(--text-secondary);
}

.tip {
    @extend .typo-body1;

    padding-bottom: var(--double-space);
    color: var(--text-secondary);
}