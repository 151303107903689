.ant-modal-root {
    .ant-modal-mask {
        .ant-modal-wrap {
            z-index: var(--z-popup);
        }
    }

    .ant-modal-wrap {
        z-index: var(--z-popup);
    }
}